import { Check } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	LinearProgress,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import { isObject } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useAuthContext } from "../../../../context";
import { useProfile } from "../../../../context/ProfileContext";
import { BasicModal, ModalContext, ModalInstallment, ToastContent, ToobarProposalDetails } from "../../../../shared/components";
import { ToolbarGridFilterProposal } from "../../../../shared/components/toolbarGridProposal/ToolbarGridFilterProposal";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../../shared/enums/profiles";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../shared/forms";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { checkByProfileGuard } from "../../../../shared/guards/ProfileGuard";
import { useDebounce, useToast } from "../../../../shared/hooks";
import { Logs, UpdateProposalLogs } from "../../../../shared/interfaces";
import { LayoutBaseDePagina } from "../../../../shared/layouts";
import { clientService } from "../../../../shared/services/api/client/clientService";
import { GetDocumentTypesResponseType } from "../../../../shared/services/api/documents/types";
import { ProposalDocumentsService } from "../../../../shared/services/api/proposal/ProposalDocumentsService";
import {
	ICancelProposal,
	ICancelProposalConclude,
	IFormInstallmentCancel,
	IInstallmentProposal,
	IProposalList,
	ISinistercheckList,
	ITotalStatusInstallmentProposal,
	IUpdateProposalSuperAdmin,
	ProposalService,
} from "../../../../shared/services/api/proposal/ProposalService";
import { ISerasaData, ISerasaResponse, serasaService } from "../../../../shared/services/api/serasa/serasaService";
import { SinisterFollowUpService } from "../../../../shared/services/api/sinister/SinisterFollowUpService";
import { SinisterService } from "../../../../shared/services/api/sinister/SinisterService";
import { IDetalheUsuario, userService } from "../../../../shared/services/api/user/userService";
import { IMessage } from "../../../../shared/types/message";
import {
	globalMaskDocument,
	globalTranslate,
	globalTranslateMaritalStatus,
	globalTranslatePayment,
	globalTranslateProfession,
	globalTranslateStatus,
} from "../../../../shared/utils";
import { formatDate, formatDateForInput } from "../../../../shared/utils/format-date";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";
import { ProposalFilesModalContent } from "./components";
import { ProposalCancellingFollowupModalContent } from "./components/ProposalCancellingFollowupModalContent";
import { ProposalFilesUploadArea } from "./components/ProposalFilesUploadArea/ProposalFileUploadArea";
import { ClickableGrid } from "./ProposalDetailPresentation.styled";
import { format } from "date-fns-tz";

interface IFormData {
	certificateNumber?: string;
	id: string;
	clientId?: string;
	analysisId?: string;
	status: string;
	name: string;
	motherName?: string;
	type_person?: string;
	document: string;
	birthDate?: string;
	canceledReason?: string;
	mobilePhone?: string;
	createdAt?: string;
	updatedAt?: string;
	canceledAt?: string;
	termBegin?: string;
	expiresAt?: string;
	expiredAt?: string;
	installmentsNumber?: string;
	createdFrom?: string;
	employmentRelationShip?: string;
	maritalStatus?: string;
	wifeName?: string;
	email?: string;
	phone?: string;
	rentalCost?: string;
	sendSerasa?: string;
	propertyType?: string;
	addressZipcode?: string;
	addressStreet?: string;
	addressResidenceType?: string;
	addressNumber?: string;
	addressComplement?: string;
	addressDistrict?: string;
	addressCity?: string;
	addressState?: string;
	addressIbge?: string;
	serasaId?: string;
	serasaResponse?: string;
	serasaAnalysisStatus?: string;
	paymentMethod?: string;
	rentMonthsCount?: string;
	residentialFee?: string;
	commercialFee?: string;
	rentAmount?: string;
	condominiumAmount?: string;
	electricEnergyAmount?: string;
	waterAmount?: string;
	gasAmount?: string;
	iptuAmount?: string;
	// analysisPlan?: string;
	assuranceMonthAmount?: string;
	lmi?: string;
	lmiAmount?: string;
	lmiBalance?: string;
	userId?: string;
	relation?: string;
	planId?: string;
	peopleId?: string;
	reasonId?: string;
	lessorName?: string;
	lessorCpf?: string;
	propertyId?: string;
	lessorTypePerson?: string;
	lessorBirthDate?: string;
	lessorEmail?: string;
	lessorPhone?: string;
	lessorMobilePhone?: string;
	client?: {
		id?: string;
		document: string;
		type_person?: string;
		status?: string;
		email?: string;
		creci?: string;
		name: string;
		trade_name?: string;
		responsible_name?: string;
		responsible_document?: string;
		phone?: string;
		mobilePhone?: string;
		address_zipcode?: string;
		address_street?: string;
		address_number?: string;
		address_complement_type?: string;
		address_complement?: string;
		address_district?: string;
		address_city?: string;
		address_state?: string;
		address_ibge?: string;
		wallet_properties_count?: string;
		accept_terms_service?: string;
		residential_fee?: string;
		commercial_fee?: string;
		created_from?: string;
		lmi?: string;
		invoice_due_date?: string;
		agency_number?: string;
		bank_code?: string;
		bank_manager?: string;
		agency_digit?: string;
		account_number?: string;
		account_digit?: string;
		pix_key?: string;
		pix_key_type?: string;
		created_at?: string;
		updated_at?: string;
		typeId?: string;
		clientType?: {
			id?: string;
			description?: string;
			status?: string;
			level?: string;
			created_at?: string;
			updated_at?: string;
		};
	};
}
interface IFormFinacial {
	amount: string;
	dueDate: string;
	obs: string;
	status?: string;
	paymentMethod?: string;
	number?: string;
	type?: string;
	typeAmount: string;
	proposalId?: string;
	clientId?: string;
}

const convertStatusSerasa = (status: string = "Não fornecida") => {
	if (status === "approved") return "Aprovado";
	else if (status === "refused") return "Reprovado";

	return status;
};

function colorText(status: string) {
	if (status === "expired") {
		return "#f0e555";
	}
	if (status === "active") {
		return "#38df61";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "suspended") {
		return "#eb3232";
	}
	if (status === "renovated") {
		return "#3761eb";
	}
	if (status === "paid") {
		return "#1ec448";
	}
	if (status === "billed") {
		return "#3761eb";
	}

	return "#202020";
}

function colorLine(parcela: number) {
	if (parcela % 2 == 0) {
		return "#e5e8eb";
	}
	return "#ffffff";
}

const maskPhone = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{2})(\d)/, "($1) $2")
		.replace(/(\d{4})(\d)/, "$1-$2")
		.replace(/(-\d{4})\d+?$/, "$1");
};

const maskCell = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{2})(\d)/, "($1) $2")
		.replace(/(\d{5})(\d)/, "$1-$2")
		.replace(/(-\d{4})\d+?$/, "$0");
};

const maskReal = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{1,2})$/, ",$1")
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

const removeMask = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/[^0-9]/g, "")
		.replace(/(\d{1,2})$/, ".$1");
};

const formEditValidationSchema: yup.SchemaOf<any> = yup.object().shape({
	id: yup.string().required("Campo Obrigatório"),
	status: yup.string().required("Campo Obrigatório"),
});

export const ProposalDetailPresentation: React.FC = () => {
	const URL = window.location.protocol + "//" + window.location.host;
	const { id = "novo" } = useParams<"id">();
	const navigate = useNavigate();
	const { formRef } = useVForm();
	const { formRef: formRefInstallment } = useVForm();
	const { formRef: formRefEdit } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingInstallmentProposal, setIsLoadingInstallmentProposal] = useState(false);
	const { debounce } = useDebounce();
	const [nome, setNome] = useState("");
	const [doc, setDoc] = useState("");
	const [clientId, setClientId] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [openModalCheckList, setOpenModalCheckList] = useState(false);
	const [openModalTypeProperty, setOpenModalTpeProperty] = useState(false);
	const [openModalInstallment, setOpenModalInstallment] = useState(false);
	const [openModalFiles, setOpenModalFiles] = useState(false);
	const [openModalCancel, setOpenModalCancel] = useState(false);
	const [openModalCancelInstallment, setOpenModalCancelInstallment] = useState(false);
	const [openModalCancelConclude, setOpenModalCancelConclude] = useState(false);
	const [openModalCancelWaiver, setOpenModalCancelWaiver] = useState(false); //desiste do cancelamento
	const [openModalFollowup, setOpenModalFollowup] = useState(false);
	const [openCertificate, setOpenCertificate] = useState(false);
	const [openModalRenovate, setOpenModalRenovate] = useState(false);
	const [status, setStatus] = useState("");
	const [docLessor, setDocLessor] = useState("");
	const [reasonCancel, setReasonCancel] = useState("");
	const toastHandler = useToast();
	const [propertyOccupied, setPropertyOccupied] = useState(false);
	const [rows, setRows] = useState<IProposalList>();
	const [checklist, setChecklist] = useState<ISinistercheckList[]>();
	const [user, setUser] = useState<IDetalheUsuario>();
	const [rowsInstallment, setRowsInstallment] = useState<IInstallmentProposal[]>([]);
	const [rowsTotalInstallment, setRowsTotalInstallment] = useState<ITotalStatusInstallmentProposal>();
	const [totalCountInstallment, setTotalCountInstallment] = useState(0);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [modalFinancial, setModalFinancial] = useState(false);
	const { permissionLevel, isSuperAdmin } = useProfile();
	const { loggedUser } = useAuthContext();
	const [statusInstallmentProposal, setStatusInstallmentProposal] = useState<string>("Todas");

	const [isFinancialModalValidityDateEnabled, setIsFinancialModalValidityDateEnabled] = useState(true);
	const [serasaResponse, setSerasaResponse] = useState<Record<string, any>>({});

	const [fileTypeId, setFileTypeId] = useState<string>();
	const [file, setFile] = useState<File>();
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [documentTypes, setDocumentTypes] = useState<GetDocumentTypesResponseType[]>([]);
	const [fileUploadedSuccessfully, setFileUploadedSuccessfully] = useState<boolean>(false);

	// logs visualização
	const [openModalLogs, setOpenModalLogs] = useState(false);
	const [openModalLogsPayload, setOpenModalLogsPayload] = useState(false);
	const [openModalLogsUpdatePayload, setOpenModalLogsUpdatePayload] = useState(false);
	const [isLoadingLogs, setIsLoadingLogs] = useState(false);

	const [openModalUpdateProposalGridLogs, setOpenModalUpdateProposalGridLogs] = useState(false);
	const [openModalUpdateProposalLogs, setOpenModalUpdateProposalLogs] = useState(false);

	const [isLoadingUpdateProposalLogs, setIsLoadingUpdateProposalLogs] = useState(false);
	const [showModalLogUpdateProposal, setShowModalLogUpdateProposal] = useState(false);

	const [dataLogs, setDataLogs] = useState<Logs[]>([]);
	const [dataUpdateProposalLogs, setDataUpdateProposalLogs] = useState<UpdateProposalLogs[]>([]);
	const [payload, setPayload] = useState<any>(undefined);
	const [differencesProposal, setDifferencesProposal] = useState<any>(undefined);
	const [idLog, setIdLog] = useState("");
	const [isRenovated, setIsRenovated] = useState(false);
	const [isLoadingLogsSerasa, setIsLoadingLogsSerasa] = useState(false);
	const [dataSerasa, setDataSerasa] = useState<ISerasaData | undefined>();
	const [openModalLogsSerasa, setOpenModalLogsSerasa] = useState(false);
	const [dataSerasaResponse, setDataSerasaResponse] = useState<ISerasaResponse | undefined>();
	const [temSinister, setTemSinister] = useState(0);
	const [idInstallmentCancel, setIdInstallmentCancel] = useState("");
	const [changeStatus, setChangeStatus] = useState("");

	const [openModalEditSuper, setOpenModalEditSuper] = useState(false);
	const [isLoadingEditSuper, setIsLoadingEditSuper] = useState(false);
	const [selectedDate, setSelectedDate] = useState("");
	const [isSinisterFinalizedByProposal, setIsSinisterFinalizedByProposal] = useState(false);

	const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
	// Último dia do mês corrente + 1 mês
	const lastDayOfMonthNextMonth = moment(firstDayOfMonth).endOf("month").add(6, "month").format("YYYY-MM-DD");

	const handleOpenModalLogsSerasa = () => {
		setOpenModalLogsSerasa(true);
	};

	const handleCloseModalLogsSerasa = () => {
		setOpenModalLogsSerasa(false);
	};

	const handleGetLogsSerasa = (analysisId: string) => {
		setIsLoadingLogsSerasa(true);
		handleOpenModalLogsSerasa();

		serasaService
			.getSerasaByAnalysisId(analysisId)
			.then((result: IMessage<ISerasaData>) => {
				setIsLoadingLogsSerasa(false);

				if (result instanceof Error) {
					handleCloseModalLogsSerasa();

					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						durationMs: 4000,
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const { message, description, status, data } = result;

					if (status != 200 && status != 400) {
						toastHandler.present({
							type: "warning",
							position: "top-right",
							durationMs: 4000,
							messageOrContent: <ToastContent title={message as string} content={description as string} />,
						});
					} else {
						const dataSerasaResponse: ISerasaResponse = JSON.parse("" + data?.data);
						setDataSerasaResponse(dataSerasaResponse);

						let income = "";
						const value = dataSerasaResponse.response?.body?.split("RENDA")[1]?.split("|")[0];

						if (value?.includes("PONTUACAO@") && value?.split("PONTUACAO@")[1]?.length) {
							income = value.split("PONTUACAO@")[1];
						}
						const dataSerasa: ISerasaData = {
							...data,
							income,
						};
						setDataSerasa(dataSerasa);
					}
				}
			})
			.catch((err: any) => {
				setIsLoadingLogsSerasa(false);
				handleCloseModalLogsSerasa();

				const { titleMessage, subtitleMessage, contentMessage } = returnMessageError(err);
				toastHandler.present({
					type: "error",
					position: "top-right",
					durationMs: 4000,
					messageOrContent: <ToastContent title={titleMessage} subtitle={subtitleMessage} content={contentMessage} />,
				});
			});
	};

	const handleOpenModalLogs = () => {
		setOpenModalLogs(true);
	};

	const handleCloseModalLogs = () => {
		setOpenModalLogs(false);
	};

	const handleOpenModalLogsPayload = () => {
		setOpenModalLogsPayload(true);
	};

	const handleCloseModalLogsPayload = () => {
		setOpenModalLogsPayload(false);
	};

	const handleOpenModalLogsUpdatePayload = () => {
		setOpenModalLogsUpdatePayload(true);
	};

	const handleCloseModalLogsUpdatePayload = () => {
		setOpenModalLogsUpdatePayload(false);
	};

	const handleOpenUpdateProposalLog = () => {
		setOpenModalUpdateProposalLogs(true);
	};

	const handleCloseModalUpdateProposalLog = () => {
		setOpenModalUpdateProposalLogs(false);
	};
	const handleGetLogs = (proposalId: string) => {
		setIsLoadingLogs(true);
		handleOpenModalLogs();
		ProposalService.getLogs(proposalId)
			.then((result) => {
				setIsLoadingLogs(false);

				if (result instanceof Error) {
					setOpenModalLogs(false);
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					setDataLogs(result);
				}
			})
			.catch((err: any) => {
				setIsLoadingLogs(false);
				setOpenModalLogs(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	};

	function findDifferences(oldProposal: any, newProposal: any): any {
		const differences: any = {};

		// Iterate through properties of oldProposal
		for (const key in oldProposal) {
			if (oldProposal.hasOwnProperty(key)) {
				// Check if property exists in newProposal and values are different
				if (newProposal.hasOwnProperty(key) && oldProposal[key] !== newProposal[key]) {
					differences[key] = {
						old: oldProposal[key],
						new: newProposal[key],
					};
				}
			}
		}

		// Iterate through properties of newProposal to find additional properties
		for (const key in newProposal) {
			if (newProposal.hasOwnProperty(key) && !oldProposal.hasOwnProperty(key)) {
				differences[key] = {
					old: undefined,
					new: newProposal[key],
				};
			}
		}

		return differences;
	}

	const handleGetLogsPayload = (payload?: any, logId: string = "") => {
		if (isObject(payload)) {
			setPayload(payload);
			setIdLog(logId);
			handleOpenModalLogsPayload();
		} else {
			handleCloseModalLogsPayload();
			toastHandler.present({
				type: "warning",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta!!"} content={"Não foi encontrado evidência de payload neste log especifico!"} />,
			});
		}
	};

	const handleGetLogsUpdatePayload = (payload?: any, logId: string = "") => {
		console.log("543.....", payload, logId);
		const { action, proposal, newProposal } = payload;
		console.log("545...", action, proposal, newProposal);
		const proposalDifferences = findDifferences(proposal, newProposal);
		console.log("547....", proposalDifferences);
		setDifferencesProposal(proposalDifferences);
		setShowModalLogUpdateProposal(true);
		if (payload) {
			setPayload(payload);
			setIdLog(logId);
			handleOpenModalLogsUpdatePayload();
			console.log("554.....");
		} else {
			handleCloseModalLogsUpdatePayload();
			toastHandler.present({
				type: "warning",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta!!"} content={"Não foi encontrado evidência de payload neste log especifico!"} />,
			});
		}
	};
	const handleGetLogUpdateProposal = (id: string) => {
		setIsLoadingUpdateProposalLogs(true);
		handleOpenUpdateProposalLog();
		ProposalService.getLogUpdateProposal(id)
			.then((result) => {
				console.log(result);

				setIsLoadingUpdateProposalLogs(false);

				if (result instanceof Error) {
					setOpenModalUpdateProposalLogs(false);
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					setDataUpdateProposalLogs(result);
				}
			})
			.catch((err: any) => {
				setIsLoadingUpdateProposalLogs(false);
				setOpenModalUpdateProposalLogs(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	};

	const onFileUploadHandler = (file: File | undefined) => {
		setFile(file);
	};

	const validToUpload = useMemo(() => {
		return file && fileTypeId;
	}, [fileTypeId, file]);

	const formValidationSchema: yup.SchemaOf<IFormFinacial> = yup.object().shape({
		amount: yup.string().required("Campo Obrigatório"),
		dueDate: yup.string().required("Campo Obrigatório"),
		obs: yup.string().required("Campo Obrigatorio"),
		status: yup.string().notRequired().default(""),
		paymentMethod: yup.string().notRequired(),
		type: yup.string().notRequired().default(""),
		typeAmount: yup.string().required("Campo Obrigatório"),
		number: yup.string().notRequired().default(""),
		proposalId: yup.string().notRequired().default(""),
		clientId: yup.string().notRequired().default(""),
	});
	const formValidationSchemaCancelInstallment: yup.SchemaOf<IFormInstallmentCancel> = yup.object().shape({
		obs: yup.string().required("Campo Obrigatório"),
	});
	const formValidationSchemaCancel: yup.SchemaOf<ICancelProposal> = yup.object().shape({
		canceledType: yup.string().required("Campo Obrigatório"),
		canceledReason: yup.string().required("Campo Obrigatório"),
		canceledAt: yup.string().required("Campo Obrigatório"),
		//  file: yup.mixed().required('File is required'),
	});

	const formValidationSchemaCancelConclude: yup.SchemaOf<ICancelProposalConclude> = yup.object().shape({
		confirmCanceledAt: yup.string().required("Campo Obrigatório"),
		confirmCanceledType: yup.string().required("Campo Obrigatório"),
		confirmCanceledReason: yup.string().required("Campo Obrigatório"),
	});

	/**
	 * Verifica se é possível renovar uma determinada regra com base em várias condições.
	 * @param {Object} rows - Objeto contendo informações sobre a regra.
	 * @param {number} permissionLevel - Nível de permissão do usuário.
	 * @param {boolean} isSuperAdmin - Indica se o usuário é um super administrador.
	 * @returns {boolean} - Retorna true se a renovação for permitida, caso contrário, false.
	 */
	//  rows: any, permissionLevel: number, isSuperAdmin: boolean
	const canRenewRule = (): boolean => {
		const hasSinister = rows?.sinister && rows?.sinister?.length > 0 ? true : false;

		// Regra: Se houver sinistro, não é possível renovação.
		if (hasSinister && !isSinisterFinalizedByProposal) return false;

    if(rows && rows.renovateAt){ //se ja foi renovada nao pode renovar novamente
      return false;
    }
		const isRenewActive = rows?.status === "active" && !rows?.renovateAt;
		const isRenewExpired = rows?.status === "expired";

		const expirationDate = rows?.expiresAt ? new Date(rows.expiresAt.substring(0, 10) + " 00:00:01") : new Date("2021-01-01");

		const daysUntilExpiration = differenceInDays(expirationDate, new Date());

		const within30DaysActive = isRenewActive && daysUntilExpiration >= -30;
		const within30DaysExpired = isRenewExpired && daysUntilExpiration >= -30 && daysUntilExpiration <= 30;

		//if (permissionLevel === 2 || isSuperAdmin) {
		return within30DaysActive || within30DaysExpired;
		//}

		// return isRenewActive && daysUntilExpiration >= 0;
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleOpenModalRenovate = () => {
		setOpenModalRenovate(true);
	};

	const handleOpenModalTypeProperty = () => {
		setOpenModalTpeProperty(true);
	};

	const handleOpenModalInstallment = () => {
		setOpenModalInstallment(true);
	};

	const handleOpenModalFinancial = () => {
		setModalFinancial(true);
	};

	const handleOpenModalFiles = () => {
		setOpenModalFiles(true);
	};

	const handleOpenModalCancel = () => {
		setOpenModalCancel(true);
	};

	const handleOpenModalCancelConclude = () => {
		setOpenModalCancelConclude(true);
	};

	const handleOpenModalCancelWaiver = () => {
		setOpenModalCancelWaiver(true);
	};

	const handleOpenModalCancelInstallment = (idInstallmentCancel: string) => {
		setOpenModalCancelInstallment(true);
		setIdInstallmentCancel(idInstallmentCancel);
	};

	const handleCloseModalCancelInstallment = (isConfirm: boolean = false) => {
		setOpenModalCancelInstallment(false);
		if (!isConfirm) {
			setIdInstallmentCancel("");
		}
	};

	const handleOpenModalFollowup = () => {
		setOpenModalFollowup(true);
	};

	const handleSave = (dados: IFormFinacial) => {
		dados.amount = removeMask(dados.amount);
		dados.paymentMethod = "billet";
		dados.status = "pending";
		dados.type = "monthly_payment";
		dados.number = (rowsInstallment.length + 1).toString();
		dados.proposalId = id;
		dados.clientId = clientId;

		formValidationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				setIsLoading(true);
				ProposalService.createInstallment(dadosvalidados).then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
					} else {
						handleOpenModal();
					}
				});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			});
	};

	const canBillInTheSelectedMonth = useCallback((month: number) => {
		const installmentInMonth = rowsInstallment.find((installment) => {
			const installmentMonth = installment.dueDate.split("-")[1];

			return Number(installmentMonth) === month;
		});

		if (!installmentInMonth) return true;

		return installmentInMonth.status === "canceled";
	}, []);

	const onFinancialModalTypeAmountChange = (typeAmount: string) => {
		const isEnabled = typeAmount !== "add"; // alterado para liberar dar desconto posterior  const isEnabled = typeAmount !== "discount" && typeAmount !== "add";
		setIsFinancialModalValidityDateEnabled(isEnabled);
		if (!isEnabled) {
			formRef.current?.setFieldValue("dueDate", "");
			const now = new Date();
			const canBill = canBillInTheSelectedMonth(now.getMonth() + 1);

			if (canBill) {
				formRef.current?.setFieldValue("dueDate", formatDateForInput(now));
			} else {
				const nextMonthDate = new Date(now.getFullYear(), now.getMonth() + 1, 1, 12);

				formRef.current?.setFieldValue("dueDate", formatDateForInput(nextMonthDate));

				toastHandler.present({
					durationMs: 5000,
					type: "warning",
					position: "top-right",
					messageOrContent: (
						<ToastContent title={"Aviso"} content={"Já existe uma fatura no mês corrente. O registro será lançado para o mês seguinte."} />
					),
				});
			}
		} else {
			const validityDateAsString = formRef?.current?.getData().dueDate;

			if (validityDateAsString) {
				takeActionOnValidityDateString(validityDateAsString);
			}
		}
	};

	const onValidityDateChange = (value: string) => {
		if (!value) return;

		takeActionOnValidityDateString(value);
	};

	const takeActionOnValidityDateString = useCallback((date: string) => {
		const splitValidityDateAsString = date.split("-");

		const validityDate = new Date(Number(splitValidityDateAsString[0]), Number(splitValidityDateAsString[1]) - 1, 1, 12);

		const canBill = canBillInTheSelectedMonth(validityDate.getMonth() + 1);

		if (!canBill) {
			const nextMonthDate = new Date(validityDate.setMonth(validityDate.getMonth() + 1));

			formRef.current?.setFieldValue("dueDate", formatDateForInput(nextMonthDate));

			toastHandler.present({
				durationMs: 5000,
				type: "warning",
				position: "top-right",
				messageOrContent: (
					<ToastContent title={"Aviso"} content={"Já existe uma fatura no mês da data selecionada. O registro será lançado para o mês posterior."} />
				),
			});
		}
	}, []);

	const handleCertificate = () => {
		setIsLoading(false);
		ProposalService.getCertificate(id);
	};

	const handlechecklist = (ocuped: string) => {
		setIsLoading(false);
		ProposalService.getSinisterCheck(ocuped).then((result) => {
			if (result) {
				const novo = result;
				setChecklist(result.data), setOpenModalCheckList(true);
			}
			// if(result){
			//    const {data , totalCount} = result[0];
			// }
		});
	};

	const handleContract = () => {
		ProposalService.getContract(id)
			.then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);
				const message = contentMessage?.includes("code 500")
					? "Não foi possível abrir o Contrato, entre em contato com nosso atendimento."
					: contentMessage;

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={message} />,
				});
			});
	};

	const handleCancelProposal = (dados: ICancelProposal) => {
		let hasDateInvalid: boolean = false;
		let titleMessage: string = "";
		let contentMessage: string = "";
		let type: "error" | "success" | "warning" | "info" = "warning";

		if (typeof isDateValid() == "string" && isDateValid() == "") {
			hasDateInvalid = true;
			titleMessage = "Atenção no campo da data de cancelamento!";
			contentMessage = "Data de cancelamento está vazio, verifique!";
		} else if (!isDateValid()) {
			type = "error";
			hasDateInvalid = true;
			titleMessage = "Erro ao lançar data de cancelamento";
			contentMessage = "Não é permitido lançar a data de cancelamento maior que a data atual, verifique!";
		}

		if (hasDateInvalid) {
			setSelectedDate("");
			toastHandler.present({
				type,
				position: "top-right",
				durationMs: 6000,
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});

			return;
		}

		setSelectedDate("");
		formValidationSchemaCancel
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				try {
					if (file) {
						ProposalDocumentsService.uploadProposalDocument(id, "cd8a1b83-35ec-4dfc-a837-513e494007e8", file)
							.then((resultFile) => {
								if (resultFile.status == 400) {
									toastHandler.present({
										type: "error",
										position: "top-right",
										messageOrContent: (
											<ToastContent title={"Sucesso"} content={"Erro ao cancelar garantia   |  " + resultFile.data.message} />
										),
									});
								} else {
									toastHandler.present({
										type: "success",
										messageOrContent: "Arquivo enviado com sucesso!",
										position: "top-center",
									});
									setFileUploadedSuccessfully(true);
									setTimeout(() => {
										setFileUploadedSuccessfully(false);
									}, 250);
									ProposalService.updateCanceledId(id, { ...dados }).then((result) => {
										setIsLoading(false);
										if (result instanceof Error) {
											toastHandler.present({
												type: "error",
												position: "top-right",
												messageOrContent: (
													<ToastContent title={"Sucesso"} content={"Erro ao cancelar garantia   |  " + result.message} />
												),
											});
										} else {
											toastHandler.present({
												type: "success",
												position: "top-right",
												messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelamento iniciado com sucesso"} />,
											});
											navigate(`/garantias/`);
										}
									});
								}
							})
							.catch((errors) => {
								toastHandler.present({
									type: "error",
									position: "top-right",
									messageOrContent: <ToastContent title={"Erro Arquivo"} content={"Erro ao enviar Arquivo   |  " + errors.message} />,
								});
							});
					}
				} catch (e: any) {
					toastHandler.present({
						type: "error",
						messageOrContent: e.message,
						position: "top-center",
					});
				} finally {
					setIsUploading(false);
				}
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			});
	};

	const handleCancelProposalConclude = (dados: ICancelProposalConclude) => {
		formValidationSchemaCancelConclude
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				try {
					ProposalService.updateConfirmCancelId(id, { ...dados }).then((result) => {
						setIsLoading(false);

						if (result instanceof Error) {
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={"Sucesso"} content={"Erro ao cancelar garantia   |  " + result.message} />,
							});
						} else {
							toastHandler.present({
								type: "success",
								position: "top-right",
								messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelamento efetivado com sucesso"} />,
							});
							navigate(`/garantias/`);
						}
					});
					if (file) {
						ProposalDocumentsService.uploadProposalDocument(id, "cd8a1b83-35ec-4dfc-a837-513e494007e8", file)
							.then((resultFile) => {
								if (resultFile.status == 400) {
									toastHandler.present({
										type: "error",
										position: "top-right",
										messageOrContent: (
											<ToastContent title={"Sucesso"} content={"Erro ao cancelar garantia   |  " + resultFile.data.message} />
										),
									});
								} else {
									toastHandler.present({
										type: "success",
										messageOrContent: "Arquivo enviado com sucesso!",
										position: "top-center",
									});
									setFileUploadedSuccessfully(true);
									setTimeout(() => {
										setFileUploadedSuccessfully(false);
									}, 250);
									ProposalService.updateConfirmCancelId(id, { ...dados }).then((result) => {
										setIsLoading(false);
										if (result instanceof Error) {
											toastHandler.present({
												type: "error",
												position: "top-right",
												messageOrContent: (
													<ToastContent title={"Sucesso"} content={"Erro ao cancelar garantia   |  " + result.message} />
												),
											});
										} else {
											toastHandler.present({
												type: "success",
												position: "top-right",
												messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelamento iniciado com sucesso"} />,
											});
											navigate(`/garantias/`);
										}
									});
								}
							})
							.catch((errors) => {
								toastHandler.present({
									type: "error",
									position: "top-right",
									messageOrContent: <ToastContent title={"Erro Arquivo"} content={"Erro ao enviar Arquivo   |  " + errors.message} />,
								});
							});
					}
				} catch (e: any) {
					toastHandler.present({
						type: "error",
						messageOrContent: e.message,
						position: "top-center",
					});
				} finally {
					setIsUploading(false);
				}
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			});
	};

	const handleSinister = () => {
		const busy_Property = propertyOccupied ? "busy" : "not_busy";
		const proposalId = id;
		const status = "sketch";

		SinisterService.create(busy_Property, proposalId, status, clientId).then((result) => {
			setIsLoading(false);
			console.log("criando sinister");
			if (result instanceof Error) {
				alert(result.message);
			} else {
				const email = result?.client && result.client.email ? result.client.email : "";
				const sinisterId = result ? result.id : "";
				console.log("criar follow up ", email, sinisterId);
				if (sinisterId != "" && email != "") {
					SinisterFollowUpService.addSinisterFollowupContact(sinisterId, email);
					// .then(
					//   (resultFollow:any) => {
					//     console.log('1189....',resultFollow)
					//     if (resultFollow instanceof Error) {
					//       toastHandler.present({
					//         type: 'error',
					//         position: 'top-right',
					//         messageOrContent: <ToastContent title={'Sucesso'} content={'Erro ao desistir do cancelamento de garantia   |  ' + resultFollow.message} />
					//       })
					//       // alert(result.message);
					//     }
					//   })
				}
			}
			navigate(`/sinistros/`);
		});
	};

	const handleConfirmCancelProposalWaiver = () => {
		{
			ProposalService.updateConfirmCancelWaiver(id).then((result) => {
				setIsLoading(false);

				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Erro ao desistir do cancelamento de garantia   |  " + result.message} />,
					});
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Garantia ativada com sucesso"} />,
					});
					navigate(`/garantias/`);
				}
			});
		}
	};

	const getDetailsProposal = () => {
		setIsLoading(true);
		ProposalService.getById(`${id}`)
			.then((result) => {
				console.log("Row", result);
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
					navigate("/proposal");
				} else {
					setNome(result.name);
					setDoc(result.document);
					setClientId(result.clientId);
					setDocLessor(result.lessorCpf ? result.lessorCpf : "");
					setRows(result);
					setStatus(result.status);
					formRefEdit?.current?.setData({
						status: result.status,
					});

					const sinisterAbertos = result.sinister
						? result.sinister.filter((element) => {
								if (element.status != "finalized" && element.status != "canceled") {
									return element;
								}
						  })
						: undefined;
					if (sinisterAbertos && sinisterAbertos.length > 0) {
						setTemSinister(sinisterAbertos.length);
					}

					const resultUser: any = {
						name: "N/D",
						email: "N/D",
					};

					if (result.createdFrom == "web_app") {
						// web api

						if (!result?.userId) {
							setUser(resultUser);
						} else {
							userService
								.getByID(`${result.userId}`)
								.then((result: any) => {
									setIsLoading(false);
									if (result instanceof Error) {
										const titleMessage = "Alerta!!";
										const contentMessage = "Erro: " + result?.message;

										toastHandler.present({
											type: "error",
											position: "top-right",
											messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
										});
									} else {
										setUser(result);
									}
								})
								.catch((err: any) => {
									setIsLoading(false);
									const { titleMessage, contentMessage } = returnMessageError(err);

									toastHandler.present({
										type: "error",
										position: "top-right",
										messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
									});
								});
						}
					} else if (result.createdFrom == "web_service") {
						// integração 4seg

						if (!result?.clientId) {
							setUser(resultUser);
						} else {
							clientService
								.getPartners(result.clientId)
								.then((result: any) => {
									setIsLoading(false);
									if (result instanceof Error) {
										const titleMessage = "Alerta!!";
										const contentMessage = "Erro: " + result?.message;

										toastHandler.present({
											type: "error",
											position: "top-right",
											messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
										});
									} else {
										setUser(result?.data[0]);
									}
								})
								.catch((err: any) => {
									setIsLoading(false);
									const { titleMessage, contentMessage } = returnMessageError(err);

									toastHandler.present({
										type: "error",
										position: "top-right",
										messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
									});
								});
						}
					}

					try {
						const serasaResponse = JSON.parse(result?.serasaResponse as string);
						setSerasaResponse(serasaResponse);
					} catch (_) {
						console.log("Erro ao realizar parse do objeto de resposta do Serasa");
					}
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	};

	useEffect(() => {
		if (id !== "novo") {
			getDetailsProposal();
		}
	}, [id]);

	useEffect(() => {
		if (
			id !== "novo" &&
			![
				// Nega caso os status listados
				"finalized",
				"canceled",
				"according",
				"pending",
				"in_progress",
				"monthly_compensation",
			].includes(rows?.status as string) &&
			[
				// Permite caso os status listados
				"awaiting_signature",
				"debts_confession",
			].includes(rows?.status as string)
		) {
			setIsSinisterFinalizedByProposal(true);
		}
	}, [rows]);

	useEffect(() => {
		setIsLoading(true);
		debounce(() => {
			ProposalService.getAllInstallmentProposal(id).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					//alert(result.message);
				} else {
					setTotalCountInstallment(result.totalCount);
					setRowsInstallment(result.data);
				}
			});
		});
	}, []);

	useEffect(() => {
		setIsRenovated(differenceInDays(new Date(rows?.termBegin ? rows.termBegin.substring(0, 10) + " 3:00:01" : "2021-01-01 03:00:01"), new Date()) < 0);
	}, [rows?.termBegin]);
	const getStatusInstallmentProposal = () => {
		setIsLoadingInstallmentProposal(true);
		debounce(() => {
			ProposalService.getStatusInstallmentProposal(statusInstallmentProposal, id)
				.then((result: IInstallmentProposal[] | Error) => {
					setIsLoadingInstallmentProposal(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						setRowsInstallment(result);
					}
				})
				.catch((err: any) => {
					setIsLoadingInstallmentProposal(false);
					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	};

	const getTotalStatusInstallmentProposal = () => {
		setIsLoadingInstallmentProposal(true);
		debounce(() => {
			ProposalService.getTotalStatusInstallmentProposal(id)
				.then((result: ITotalStatusInstallmentProposal | Error) => {
					setIsLoadingInstallmentProposal(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						setRowsTotalInstallment(result);
					}
				})
				.catch((err: any) => {
					setIsLoadingInstallmentProposal(false);
					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	};

	useEffect(() => {
		getStatusInstallmentProposal();
		getTotalStatusInstallmentProposal();
	}, [statusInstallmentProposal]);

	const handleSaveInstallmentCancel = (dados: IFormInstallmentCancel) => {
		formValidationSchemaCancelInstallment
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				setIsLoadingInstallmentProposal(true);
				ProposalService.saveCancelInstallment(dadosvalidados, idInstallmentCancel).then((result: string | Error) => {
					setIsLoadingInstallmentProposal(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						handleCloseModalCancelInstallment();
						getStatusInstallmentProposal();

						toastHandler.present({
							type: "success",
							position: "top-right",
							durationMs: 3000,
							messageOrContent: <ToastContent title={"SUCESSO!"} content={"Parcela cancelada com sucesso."} />,
						});
					}
				});
			})
			.catch((errors: yup.ValidationError) => {
				setIsLoadingInstallmentProposal(false);
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRefInstallment.current?.setErrors(validationErros);

				const { titleMessage, contentMessage } = returnMessageError(errors);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	};

	const handleModalEditSuperClose = () => {
		setOpenModalEditSuper(false);
	};

	const openModalEdit = () => {
		setOpenModalEditSuper(true);
		getDetailsProposal();
	};

	const handleSaveEdit = (dados: IUpdateProposalSuperAdmin) => {
		dados.id = id;

		formEditValidationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosValidados) => {
				setIsLoadingEditSuper(true);
				ProposalService.updateProposalSuperAdmin(dadosValidados)
					.then((result: IMessage<null> | Error) => {
						setIsLoadingEditSuper(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							handleModalEditSuperClose();
							getDetailsProposal();

							const { message, description } = result;

							toastHandler.present({
								type: "success",
								position: "top-right",
								messageOrContent: <ToastContent title={message as string} content={description as string} />,
							});
						}
					})
					.catch((err: any) => {
						setIsLoadingEditSuper(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			})
			.catch((errors: yup.ValidationError) => {
				setIsLoadingEditSuper(false);
				const validationErros: IVFormErros = {};
				errors?.inner?.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRefEdit?.current?.setErrors(validationErros);
			});
	};

	const isDateValid = () => {
		if (!selectedDate) return "";

		const currentDate = new Date();
		const selectedDateObj = new Date(selectedDate);

		return selectedDateObj <= currentDate;
	};

	return (
		<LayoutBaseDePagina
			titulo="Garantias"
			subtitulo={`Detalhes da Garantia  [ ${nome} - ${globalMaskDocument(doc)} ]`}
			barraDeFerramentas={
				<ToobarProposalDetails
					isExpired={rows?.status == "expired"}
					clickBack={() => navigate("/garantias")}
					clickSinister={handleOpenModalTypeProperty}
					clickInstallment={handleOpenModalInstallment}
					clickCertificate={handleCertificate}
					clickFinancial={handleOpenModalFinancial}
					clickFiles={handleOpenModalFiles}
					clickCancel={() => setOpenModalCancel(true)}
					clickRenovate={handleOpenModalRenovate}
					clickEdit={() => navigate(`/garantias/alterar/${id}`)}
					showButtonEdit={
						permissionLevel === 1 || checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Update) ? true : false
					}
					showButtonCancel={
						rows?.status == "active" && checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Cancel) ? true : false
					}
					clickFollowup={handleOpenModalFollowup}
					clickContract={handleContract}
					showButtonFinancial={permissionLevel === 1 ? true : false}
					showButtonContract={rows?.createdFrom == "web_service" ? false : true}
					showButtonSinister={
						checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Add) &&
						rows?.status &&
						["active", "expires"].includes(rows.status) &&
						((isRenovated && rows?.renovatedId) || !rows?.renovatedId)
							? temSinister > 0 && !isSinisterFinalizedByProposal
								? false
								: true
							: false
					}
					showButtonRenovate={canRenewRule()}
					showButtonCancellingFollowup={rows?.status == "suspended" || rows?.status == "canceled"}
				/>
			}
		>
			{openModal && (
				<BasicModal
					isCloseModal={() => setOpenModal(false)}
					isOpenModal={openModal}
					messageType={"success"}
					showButtonCancel={false}
					textButtonConfirm={"OK"}
					onclickConfirm={() => {
						setOpenModal(false), navigate("/garantias");
					}}
					IMessage={"Contratação realizada com sucesso !"}
				/>
			)}

			{openModalCheckList && (
				<ModalContext
					isCloseModal={() => setOpenModalCheckList(false)}
					isOpenModal={openModalCheckList}
					messageType={"info"}
					showButtonCancel={true}
					showButtonConfirm={true}
					textButtonConfirm={"Aceito os termos"}
					textButtonCancel={"Fechar"}
					onclickConfirm={() => handleSinister()}
					ITitle={propertyOccupied ? "Termos de abertura de Sinistro Imóvel Ocupado" : "Termos de abertura de Sinistro Imóvel Desocupado"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography
								paddingY={"5px"}
								paddingX={"25px"}
								borderBottom={"1px solid "}
								borderColor={"#efefef"}
								sx={{ backgroundColor: `'red'` }}
							>
								Para celeridade e regulação do seu sinistro, favor anexar os documentos solicitados abaixo, para não correr risco de recusa no
								sinistro. Já começamos a contar o prazo de análise e pagamento de sinistro após o recebimento da documentação completa.
							</Typography>
							<Box margin={1} display="flex" flexDirection="column" component={Box}>
								{checklist &&
									checklist?.map((row, key) => (
										<>
											<Typography
												paddingY={"5px"}
												borderBottom={"1px solid "}
												borderColor={"#efefef"}
												sx={{ backgroundColor: `${key % 2 ? "whrite" : "#f6f0ff"}` }}
											>
												<Icon sx={{ color: "primary", fontSize: "small", mr: "8px" }}>done_outline';</Icon>
												{(row.ocupation != "busy" && row.open == "required") || (row.ocupation != "open" && row.busy == "required")
													? `${row.description}  - Obrigatório`
													: `${row.description}`}
											</Typography>
										</>
									))}
							</Box>
						</Box>,
					]}
				/>
			)}

			{openModalInstallment && (
				<ModalInstallment
					isCloseModal={() => setOpenModalInstallment(false)}
					isOpenModal={openModalInstallment}
					messageType={"info"}
					showButtonCancel={false}
					textButtonConfirm={"Fechar"}
					onclickConfirm={() => setOpenModalInstallment(false)}
					onCloseModal={() => setOpenModalInstallment(false)}
					ITitle={"Extrato de garantia"}
					IMessage={[
						<Grid container>
							<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Box} padding={3} spacing={2}>
								<ToolbarGridFilterProposal
									onClickAll={() => setStatusInstallmentProposal("Todas")}
									onClickActive={() => setStatusInstallmentProposal("active")}
									onClickCanceled={() => setStatusInstallmentProposal("canceled")}
									selectAll={statusInstallmentProposal === "Todas"}
									selectAtive={statusInstallmentProposal === "active"}
									selectCanceled={statusInstallmentProposal === "canceled"}
									textButtonAll={`Todas (${rowsTotalInstallment!.total})`}
									textButtonActive={`Ativas (${rowsTotalInstallment!.ativo})`}
									textButtonCanceled={`Canceladas (${rowsTotalInstallment!.cancelada})`}
								/>

								{isLoadingInstallmentProposal && <LinearProgress sx={{ width: "100%" }} variant="indeterminate" />}

								{rowsInstallment?.map((row: IInstallmentProposal) => (
									<Grid container item direction="row" key={row.id} spacing={1}>
										<Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ backgroundColor: colorLine(row.number) }}>
											<Typography variant="caption" color={"text.secondary"}>
												{row.typeAmount ? globalTranslate(row.typeAmount) : ""} {row.typeAmount === "parcel" ? row.number : ""}
											</Typography>
											<Typography variant="subtitle2">{row.obs}</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: colorLine(row.number) }}>
											<Typography variant="caption" color={"text.secondary"}>
												Vencimento
											</Typography>
											<Typography variant="subtitle2">{moment(row.dueDate).format("L")}</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{ backgroundColor: colorLine(row.number) }}>
											<Typography variant="caption" color={"text.secondary"}>
												Valor
											</Typography>
											{row.typeAmount != "discount" ? (
												<Typography variant="subtitle2">{maskReal(row.amount)}</Typography>
											) : (
												<Typography variant="subtitle2" sx={{ color: "red" }}>
													- {maskReal(row.amount)}
												</Typography>
											)}
										</Grid>
										<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: colorLine(row.number) }}>
											<Typography variant="caption" color={"text.secondary"}>
												Status
											</Typography>
											<Typography variant="subtitle2" color={colorText(row.status)}>
												{globalTranslateStatus(row.status)}
											</Typography>
										</Grid>

										<Grid
											item
											xs={12}
											sm={12}
											md={2}
											lg={2}
											xl={2}
											sx={{ display: "flex", backgroundColor: colorLine(row.number), alignItems: "center" }}
										>
											<Typography variant="subtitle2" color={colorText(row.status)}>
												{isSuperAdmin && row?.status == "pending" && (
													<IconButton
														size="small"
														onClick={() => handleOpenModalCancelInstallment(row.id)}
														sx={{ borderRadius: "18px" }}
													>
														<Icon sx={{ color: "#7924c7" }}>close_outlinedIcon</Icon>
														<Typography
															variant="subtitle2"
															marginLeft={1}
															sx={{ color: "#7924c7", marginLeft: "0px !important", fontWeight: "normal" }}
														>
															Cancelar
														</Typography>
													</IconButton>
												)}
											</Typography>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>,
					]}
				/>
			)}

			{openModalCancelInstallment && isSuperAdmin && (
				<ModalContext
					isCloseModal={() => handleCloseModalCancelInstallment()}
					isOpenModal={openModalCancelInstallment}
					messageType={"alert"}
					showButtonCancel={false}
					showButtonConfirm={false}
					onclickConfirm={() => handleCloseModalCancelInstallment(true)}
					ITitle={"Deseja cancelar a parcela ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdSaveInstallmentCancel"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRefInstallment}
									onSubmit={handleSaveInstallmentCancel}
								>
									<Grid container item direction="column" padding={1} spacing={2}>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="obs"
													disabled={isLoadingInstallmentProposal}
												></VTextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										item
										sm={12}
										sx={{ border: "none", display: "flex", justifyContent: "center" }}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="error"
											disableElevation
											variant={"contained"}
											disabled={isLoadingInstallmentProposal}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => formRefInstallment.current?.submitForm()}
										>
											{"Cancelar parcela"}
										</Button>
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											disabled={isLoadingInstallmentProposal}
											startIcon={<Icon> closeIcon</Icon>}
											onClick={() => handleCloseModalCancelInstallment()}
										>
											{"Sair"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{loggedUser?.role == "admin" && isSuperAdmin && (
				<ModalContext
					isCloseModal={handleModalEditSuperClose}
					isOpenModal={openModalEditSuper}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					maxWidth="40%"
					showTitleIcon={true}
					stylePropert={{ display: "contents" }}
					IMessage={[
						<VForm
							id="formIdSaveEdit"
							placeholder="Your placeholder"
							onPointerEnterCapture={() => console.log("Pointer entered")}
							onPointerLeaveCapture={() => console.log("Pointer left")}
							style={{ padding: "0px 30px" }}
							ref={formRefEdit}
							onSubmit={handleSaveEdit}
						>
							<Box margin={1} component={Paper} sx={{ height: "250px", border: "0" }} variant="outlined">
								<Typography variant="h5" pb={2} sx={{ textAlign: "center" }}>
									Alteração de status da Garantia
								</Typography>

								{rows?.status == "expired" && changeStatus == "active" ? (
									<Grid
										container
										item
										direction={"row"}
										xs={12}
										sx={{
											borderRadius: "5px",
											alignItems: "center",
											display: "flex",
											height: "50px",
											marginLeft: "10px",
											marginTop: "10px",
											maxWidth: "94%",
											width: "94%",
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{
												color: "#a30d12",
												flex: "auto",
												textAlign: "center",
												marginTop: "-10px",
												backgroundColor: "#ffe0e0",
												padding: 1,
												borderRadius: 3,
											}}
										>
											Ao mudar o status de Expirada para Vigente, ao final do dia corrente o sistema vai expirar a garantia novamente caso
											o fim de vigência seja atingido novamente!!
										</Typography>
									</Grid>
								) : (
									<div style={{ height: "58px" }} />
								)}
								<Box padding={1}>
									<VTextField
										fullWidth
										label="Status"
										name="status"
										sx={{ width: "60%" }}
										disabled={isLoadingEditSuper}
										select
										onChange={(e) => setChangeStatus(e.target.value)}
									>
										{/* {permissionLevel == 1 && (
                      <MenuItem value="expired">Expirada</MenuItem>
                    )}
                    {permissionLevel == 1 && (
                      <MenuItem style={{ display: rows?.status == "expired" ? "none" : "block" }} value="canceled">Cancelada</MenuItem>
                    )} */}
										<MenuItem value="active">Vigente</MenuItem>
										<MenuItem style={{ display: rows?.status == "expired" ? "none" : "block" }} value="suspended">
											Suspensa
										</MenuItem>
										<MenuItem style={{ display: rows?.status == "expired" ? "none" : "block" }} value="renovated">
											Renovada
										</MenuItem>
									</VTextField>
								</Box>

								<Box sx={{ display: "flex", flexDirection: "row", gap: 2, paddingY: 4, justifyContent: "center" }}>
									{isLoadingEditSuper ? (
										<CircularProgress style={{ height: "20px", marginRight: "40px", marginLeft: "10px" }} variant="indeterminate" />
									) : (
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={<Check />}
											onClick={() => formRefEdit?.current?.submitForm()}
										>
											Confirmar
										</Button>
									)}
									<Button color="primary" disableElevation variant={"outlined"} startIcon={<CloseIcon />} onClick={handleModalEditSuperClose}>
										Sair
									</Button>
								</Box>
							</Box>
						</VForm>,
					]}
				/>
			)}

			{modalFinancial && (
				<ModalContext
					isCloseModal={() => setModalFinancial(false)}
					isOpenModal={modalFinancial}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					onclickConfirm={() => {}}
					ITitle={"Lançamento Financeiro"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<VForm
								id="formIdSave"
								placeholder="Your placeholder"
								onPointerEnterCapture={() => console.log("Pointer entered")}
								onPointerLeaveCapture={() => console.log("Pointer left")}
								ref={formRef}
								onSubmit={handleSave}
							>
								<Grid container direction="column" padding={1} spacing={2}>
									{isLoading && (
										<Grid item>
											<LinearProgress variant="indeterminate" />
										</Grid>
									)}

									<Grid container item direction="row" spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<VTextField fullWidth label="Descrição" name="obs" />
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={2}>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<VTextField
												fullWidth
												label="Tipo de lançamento"
												name="typeAmount"
												select
												onChange={(event) => onFinancialModalTypeAmountChange(event.target.value)}
											>
												<MenuItem value="discount"> Desconto</MenuItem>
												<MenuItem value="add"> Acrescimo</MenuItem>
												<MenuItem value="parcel"> Parcela</MenuItem>
											</VTextField>
										</Grid>

										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<VMaskTextField
												fullWidth
												label="Valor"
												name="amount"
												mask="real"
												InputProps={{
													startAdornment: <InputAdornment position="start"></InputAdornment>,
												}}
											/>
										</Grid>

										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<VTextField
												fullWidth
												label="Vencimento"
												name="dueDate"
												type="date"
												defaultValue={moment()}
												inputProps={{
													min: firstDayOfMonth,
												}}
												onChange={(event) => onValidityDateChange(event.target.value)}
												disabled={!isFinancialModalValidityDateEnabled}
											></VTextField>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									container
									sm={12}
									sx={{
										border: "none",
										display: "flex",
										justifyContent: "center",
									}}
									display={"flex"}
									flexDirection={smDown ? "column" : "row"}
									padding={2}
									gap={2}
								>
									<Button
										color="primary"
										disableElevation
										variant={"contained"}
										startIcon={<Icon> checkIcon</Icon>}
										onClick={() => {
											formRef.current?.submitForm();
										}}
									>
										{"Confirmar"}
									</Button>
									<Button
										color="primary"
										disableElevation
										variant={"outlined"}
										startIcon={<Icon> arrow_back_Ico</Icon>}
										onClick={() => {
											setModalFinancial(false);
										}}
									>
										{"Voltar"}
									</Button>
								</Grid>
							</VForm>
						</Box>,
					]}
				/>
			)}

			{openModalTypeProperty && (
				<BasicModal
					isCloseModal={() => {
						setOpenModalTpeProperty(false), setOpenModalCheckList(true), setPropertyOccupied(false);
					}}
					isOpenModal={openModalTypeProperty}
					messageType={"info"}
					showButtonCancel={true}
					showButtonClose={true}
					textButtonConfirm={"Imóvel Ocupado"}
					textButtonCancel={"Imóvel Desocupado"}
					textButtonClose={"Desistir"}
					onclickConfirm={() => {
						setOpenModalTpeProperty(false),
							handlechecklist("busy"),
							//setOpenModalCheckList(true),
							setPropertyOccupied(true);
					}}
					onclickCancel={() => {
						setOpenModalTpeProperty(false),
							handlechecklist("open"),
							// setOpenModalCheckList(true),
							setPropertyOccupied(false);
					}}
					onclickClose={() => {
						setOpenModalTpeProperty(false), setOpenModalCheckList(false), setPropertyOccupied(false);
					}}
					ITitle={"Tipo de Sinistro"}
					IMessage={"Informe a condição do imóvel para abertura do sinistro"}
				/>
			)}

			{openModalRenovate && (
				<BasicModal
					isCloseModal={() => {
						setOpenModalRenovate(false);
					}}
					isOpenModal={openModalRenovate}
					messageType={"info"}
					showButtonCancel={true}
					textButtonConfirm={"Renovar"}
					textButtonCancel={"Cancelar"}
					onclickConfirm={() => {
						setOpenModalRenovate(false);
						navigate(`/garantias/renovar/${id}`);
					}}
					onclickCancel={() => setOpenModalRenovate(false)}
					ITitle={"Confirma a renovação da garantia"}
					IMessage={""}
				/>
			)}

			{openModalCancel && (
				<ModalContext
					isCloseModal={() => setOpenModalCancel(false)}
					isOpenModal={openModalCancel}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						handleOpenModalCancel();
					}}
					ITitle={"Deseja cancelar a garantia ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdCancelProposal"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancelProposal}
								>
									<Grid container direction="column" spacing={1}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Box padding={2} style={{ background: "#ffe7e7", borderRadius: 8 }}>
											<Typography color={"red"}>
												Para efetivação do cancelamento, é necessário anexar o termo de rescisão contratual de acordo com o motivo e os
												documentos disponíveis para o download.
											</Typography>
										</Box>
										<Button
											sx={{
												color: "primary",
												margin: 0,
												padding: 0,
												marginBottom: 4,
												textTransform: "none",
												fontSize: "1rem",
												"&.MuiButtonBase-root:hover": {
													bgcolor: "transparent",
												},
											}}
											onClick={() => window.open(`${URL}/gerais`)}
										>
											Veja aqui documentos para download.
										</Button>

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} md={8} lg={8} xl={8}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="canceledType"
													disabled={isLoading}
													defaultValue="Outros"
													select
													onChange={(e) => setReasonCancel(e.target.value)}
												>
													{/* <MenuItem value={"Desistência da Locação"}>Desistência da Locação</MenuItem> */}
													{/* <MenuItem value={"Mudou de imobiliária/imóvel"}>Mudou de imobiliária/imóvel</MenuItem> */}
													{/* <MenuItem value={"Duplicidade de Garantia"}>Duplicidade de análise</MenuItem> */}
													{/* <MenuItem value={"Optou por caução ou fiador"}>Optou por caução ou fiador</MenuItem>
                            <MenuItem value={"Optou por outra garantia/seguro"}>Optou por outra garantia/seguro</MenuItem> */}
													<MenuItem value={"Preço"}>Preço</MenuItem>
													<MenuItem value={"Compra do imóvel"}>Compra do imóvel</MenuItem>
													<MenuItem value={"Desistência da locação"}>Desistência da locação</MenuItem>
													<MenuItem value={"Distrato locador e imobiliária"}>Distrato locador e imobiliária</MenuItem>
													<MenuItem value={"Duplicidade de garantias"}>Duplicidade de garantias</MenuItem>
													<MenuItem value={"Garantia irregular/fraude"}>Garantia irregular/fraude</MenuItem>
													<MenuItem value={"Imissão na posse - Sinistro"}>Imissão na posse - Sinistro</MenuItem>
													<MenuItem value={"Inadimplência (imobiliária)"}>Inadimplência (imobiliária)</MenuItem>
													<MenuItem value={"LMI/LMG atingido"}>LMI/LMG atingido</MenuItem>
													<MenuItem value={"Termo de entrega de chaves - Sinistro"}>Termo de entrega de chaves - Sinistro</MenuItem>
													<MenuItem value={"Termo de entrega de chaves e confissão de dívidas"}>
														Termo de entrega de chaves e confissão de dívidas
													</MenuItem>
													<MenuItem value={"Termo de entrega de chaves e inexistência de débitos - imobiliária"}>
														Termo de entrega de chaves e inexistência de débitos - imobiliária
													</MenuItem>
													<MenuItem value={"Termo de revogação/declaração de responsabilidade"}>
														Termo de revogação/declaração de responsabilidade
													</MenuItem>
													<MenuItem value={"Troca de garantia"}>Troca de garantia</MenuItem>
													<MenuItem value={"Troca de imobiliária/estipulante/administradora"}>
														Troca de imobiliária/estipulante/administradora
													</MenuItem>
													<MenuItem value={"Troca de locatário"}>Troca de locatário</MenuItem>
													<MenuItem value={"Troca de seguradora"}>Troca de seguradora</MenuItem>
													<MenuItem value={"Outros"}>Outros</MenuItem>
												</VTextField>
											</Grid>
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<VTextField
													fullWidth
													label="Data do cancelamento do contrato"
													name="canceledAt"
													disabled={false}
													type="date"
													onChange={(event: any) => {
														setSelectedDate(event.target.value);
													}}
												/>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField fullWidth label="Descreva o motivo" name="canceledReason" disabled={isLoading} multiline rows={4} />
											</Grid>
										</Grid>
									</Grid>

									<ProposalFilesUploadArea
										onFileUploadHandler={onFileUploadHandler}
										isLoadingProp={isUploading}
										clear={fileUploadedSuccessfully}
									/>

									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										{reasonCancel != "" ? (
											<Button
												color="primary"
												disableElevation
												variant={"contained"}
												startIcon={<Icon> checkIcon</Icon>}
												onClick={() => {
													if (!file) {
														toastHandler.present({
															type: "error",
															position: "top-right",
															messageOrContent: (
																<ToastContent
																	title={"Sucesso"}
																	content={"Arquivo Termo de Entrega de Chaves e quitação de Débito Requerido"}
																/>
															),
														});
													} else {
														formRef.current?.submitForm();
													}
												}}
											>
												{"Cancelar Garantia"}
											</Button>
										) : (
											""
										)}
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setOpenModalCancel(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{openModalCancelConclude && (
				<ModalContext
					isCloseModal={() => setOpenModalCancelConclude(false)}
					isOpenModal={openModalCancelConclude}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						handleOpenModalCancelConclude();
					}}
					ITitle={"Deseja finalizar o cancelamento de garantia ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdProposalConclude"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancelProposalConclude}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Typography mb={3} color={"red"}>
											{`Data informada pela imobiliária para o término do Contrato de garantia  ${moment(rows?.canceledAt).format("L")} `}
										</Typography>
										<Typography mb={3} color={"red"}>
											A alteração da data de confirmação de cancelamento do contrato de garantia afetará as parcelas a serem canceladas
										</Typography>

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} md={8} lg={8} xl={8}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="confirmCanceledType"
													disabled={isLoading}
													defaultValue="Outros"
													select
													onChange={(e) => setReasonCancel(e.target.value)}
												>
													{/* <MenuItem value={"Desistência da Locação"}>Desistência da Locação</MenuItem> */}
													{/* <MenuItem value={"Mudou de imobiliária/imóvel"}>Mudou de imobiliária/imóvel</MenuItem> */}
													{/* <MenuItem value={"Duplicidade de Garantia"}>Duplicidade de análise</MenuItem> */}
													{/* <MenuItem value={"Optou por caução ou fiador"}>Optou por caução ou fiador</MenuItem>
                            <MenuItem value={"Optou por outra garantia/seguro"}>Optou por outra garantia/seguro</MenuItem> */}
													<MenuItem value={"Preço"}>Preço</MenuItem>
													<MenuItem value={"Compra do imóvel"}>Compra do imóvel</MenuItem>
													<MenuItem value={"Desistência da locação"}>Desistência da locação</MenuItem>
													<MenuItem value={"Distrato locador e imobiliária"}>Distrato locador e imobiliária</MenuItem>
													<MenuItem value={"Duplicidade de garantias"}>Duplicidade de garantias</MenuItem>
													<MenuItem value={"Garantia irregular/fraude"}>Garantia irregular/fraude</MenuItem>
													<MenuItem value={"Imissão na posse - Sinistro"}>Imissão na posse - Sinistro</MenuItem>
													<MenuItem value={"Inadimplência (imobiliária)"}>Inadimplência (imobiliária)</MenuItem>
													<MenuItem value={"LMI/LMG atingido"}>LMI/LMG atingido</MenuItem>
													<MenuItem value={"Termo de entrega de chaves - Sinistro"}>Termo de entrega de chaves - Sinistro</MenuItem>
													<MenuItem value={"Termo de entrega de chaves e confissão de dívidas"}>
														Termo de entrega de chaves e confissão de dívidas
													</MenuItem>
													<MenuItem value={"Termo de entrega de chaves e inexistência de débitos - imobiliária"}>
														Termo de entrega de chaves e inexistência de débitos - imobiliária
													</MenuItem>
													<MenuItem value={"Termo de revogação/declaração de responsabilidade"}>
														Termo de revogação/declaração de responsabilidade
													</MenuItem>
													<MenuItem value={"Troca de garantia"}>Troca de garantia</MenuItem>
													<MenuItem value={"Troca de imobiliária/estipulante/administradora"}>
														Troca de imobiliária/estipulante/administradora
													</MenuItem>
													<MenuItem value={"Troca de locatário"}>Troca de locatário</MenuItem>
													<MenuItem value={"Troca de seguradora"}>Troca de seguradora</MenuItem>
													<MenuItem value={"Outros"}>Outros</MenuItem>
												</VTextField>
											</Grid>
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<VTextField
													fullWidth
													label="Data para o término do Contrato de garantia para efeito de cancelamento da garantia"
													name="confirmCanceledAt"
													disabled={false}
													type="date"
												/>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo"
													name="confirmCanceledReason"
													disabled={isLoading}
													multiline
													rows={4}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="error"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												formRef.current?.submitForm();
											}}
										>
											{"Concluir Cancelamento"}
										</Button>

										<Button
											color="info"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setOpenModalCancelConclude(false);
												//     navigate('/garantias/');
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{openModalCancelWaiver && (
				<ModalContext
					isCloseModal={() => setOpenModalCancelWaiver(false)}
					isOpenModal={openModalCancelWaiver}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					onclickConfirm={() => {
						handleOpenModalCancelConclude();
					}}
					ITitle={"Confirma a desistência em cancelar a garantia ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdCancelProposalConclude"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancelProposalConclude}
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Typography mb={3} color={"red"}>
											A Desistência do Cancelamento da Garantia, voltará para o status de ativada
										</Typography>
									</Grid>
									<Grid
										container
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="error"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												handleConfirmCancelProposalWaiver();
											}}
										>
											{"Desisitir do Cancelamento"}
										</Button>
										<Button
											color="info"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setOpenModalCancelWaiver(false);
												//     navigate('/garantias/');
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{openModalFiles && (
				<ModalContext
					isCloseModal={() => setOpenModalFiles(false)}
					isOpenModal={openModalFiles}
					messageType={"question"}
					showButtonCancel={false}
					showButtonConfirm={false}
					showTitleIcon={false}
					textButtonCancel="Fechar"
					onclickConfirm={() => {}}
					enableBackdropClose={true}
					overrideWidthValue={"45%"}
					ITitle={"Arquivos"}
					IMessage={[<ProposalFilesModalContent proposalId={id} />]}
				/>
			)}

			{openModalFollowup && (
				<ModalContext
					isCloseModal={() => setOpenModalFollowup(false)}
					isOpenModal={openModalFollowup}
					messageType={"question"}
					showButtonCancel={false}
					showButtonConfirm={false}
					showTitleIcon={false}
					textButtonCancel="Fechar"
					enableBackdropClose={true}
					overrideWidthValue={"45%"}
					overrideHeightValue={"100%"}
					ITitle={"Followup de cancelamento"}
					IMessage={[<ProposalCancellingFollowupModalContent proposalId={id} />]}
				/>
			)}

			<Grid container spacing={2} sx={{ width: "100%", maxWidth: "1300px" }}>
				{(rows?.status == "suspended" || rows?.status == "canceled") && (
					<Grid item xs={12} md={12}>
						<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1} ml={1}>
							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12}>
									<Typography variant="h6" color={"red"}>
										Cancelamento de Garantia
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Data do Cancelamento
									</Typography>
									<Typography variant="subtitle2">{moment(rows?.canceledOpen).format("L")}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Cancelamento do Contrato
									</Typography>
									<Typography variant="subtitle2">{moment(rows?.canceledAt).format("L")}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Efetivação do Cancelamento
									</Typography>
									<Typography variant="subtitle2">
										{rows?.canceledConclude ? moment(rows?.canceledConclude).format("L") : "Em processamento"}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Motivo
									</Typography>
									<Typography variant="subtitle2">{rows.canceledType}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Descrição
									</Typography>
									<Typography variant="subtitle2">{rows.canceledReason}</Typography>
								</Grid>

								<Grid container item direction="row" spacing={10} display={"flex"} justifyContent={"start"}>
									<Grid item xs={12} md={6} lg={4} xl={2}>
										{(permissionLevel == 1 ||
											checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.UpProposalConcludeCancel)) &&
											rows.status == "suspended" && (
												<Button
													color="primary"
													disableElevation
													variant="contained"
													onClick={() => setOpenModalCancelConclude(true)}
													startIcon={<Icon> checkIcon</Icon>}
												>
													<Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
														Concluir Cancelamento
													</Typography>
												</Button>
											)}
									</Grid>
									<Grid item xs={12} md={6} lg={4} xl={2}>
										{rows.status == "suspended" && (
											<Button
												color="info"
												disableElevation
												variant="outlined"
												startIcon={<Icon>power_settings_new_outlined_Icon</Icon>}
												onClick={() => {
													setOpenModalCancelWaiver(true);
												}}
											>
												{"Desistir do Cancelamento"}
											</Button>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				)}

				<Grid item xs={12} md={12}>
					<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1} ml={1}>
						<Grid container item direction="row" spacing={1}>
							{rows?.sinister?.some((s: any) => !(s.status == "finalized" || s.status == "canceled" || s.status == "credit_recovery")) && (
								<Grid container item direction={"row"} xs={12} sx={{ background: "#fef2f2", borderRadius: "5px", alignItems: "center" }}>
									<Icon
										sx={{
											color: "#ffffff",
											background: "#db212194",
											fontSize: "12px",
											borderRadius: "40px",
											padding: "3px",
										}}
									>
										close
									</Icon>
									<Typography variant="subtitle1" sx={{ color: "#a30d12", flex: "auto", textAlign: "center" }}>
										Garantia Sinistrada.
									</Typography>
								</Grid>
							)}

							{isSinisterFinalizedByProposal && (
								<Grid container item direction={"row"} xs={12} sx={{ background: "#fef2f2", borderRadius: "5px", alignItems: "center" }}>
									<Icon
										sx={{
											color: "#ffffff",
											background: "#b8b1b1",
											fontSize: "18px",
											borderRadius: "40px",
											padding: "3px",
										}}
									>
										error_outline_Outlined_Icon
									</Icon>
									<Typography variant="subtitle1" sx={{ color: "#a30d12", flex: "auto", textAlign: "center" }}>
										Esta garantia teve um sinistro finalizado.
									</Typography>
								</Grid>
							)}

							<Grid item xs={isSuperAdmin ? 7 : 8}>
								<Typography variant="h6" color={"primary"}>
									Dados da Garantia
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} alignContent={"end"}>
								<Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
									<Typography variant="h5" color={colorText(`${rows?.status}`)}>
										{globalTranslateStatus(`${rows?.status}`)}
									</Typography>
									{isSuperAdmin && <CachedIcon sx={{ cursor: "pointer", fontSize: "30px" }} onClick={openModalEdit} />}
								</Box>
								<Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
									{rows?.renovatedId && (
										<Typography variant="subtitle1" color={colorText("")}>
											Garantia em renovação
										</Typography>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={4} alignContent={"end"}></Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
									<Typography variant="caption" color={"text.secondary"}>
										Origem da Garantia
									</Typography>
									<Typography variant="subtitle2">{globalTranslate(`${rows?.createdFrom}`)}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
									<Typography variant="caption" color={"text.secondary"}>
										Certificado
									</Typography>
									<Typography variant="subtitle2">{rows?.certificateNumber}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										{"Criada "}
									</Typography>
									<Typography variant="subtitle2">
										{/* {rows?.createdAt} */}
										{format(rows && rows.createdAt ? new Date(rows.createdAt) : new Date(), "dd/MM/yyyy", {
											timeZone: "America/Sao_Paulo",
										})}
										{/* {moment(rows?.createdAt).format("L")} */}
									</Typography>
								</Grid>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Endereço
								</Typography>
								<Typography variant="subtitle2">
									{`${
										rows?.addressStreet != null && rows?.addressStreet != ""
											? `${
													rows?.addressComplement != null && rows?.addressComplement != ""
														? `${rows?.addressStreet},${rows?.addressNumber} - ${rows?.addressComplement} -
                                            ${rows?.addressDistrict} - ${rows?.addressCity} - ${rows?.addressState} - ${rows?.addressZipcode}`
														: `${rows?.addressStreet},${rows?.addressNumber} - 
                                            ${rows?.addressDistrict} - ${rows?.addressCity} - ${rows?.addressState} - ${rows?.addressZipcode}`
											  } `
											: ""
									}`}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor Aluguel
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.rentAmount}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor Condominio
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.condominiumAmount}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor Energia Elétrica
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.electricEnergyAmount}`)}</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor Gas
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.gasAmount}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor Agua
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.waterAmount}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor IPTU
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.iptuAmount}`)}</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} md={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Início contrato locação:
								</Typography>
								<Typography variant="subtitle2">{moment(rows?.contractBegin).format("L")}</Typography>
							</Grid>
							<Grid item xs={12} md={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Fim contrato locação:
								</Typography>
								<Typography variant="subtitle2">{moment(rows?.contractEnd).format("L")}</Typography>
							</Grid>
							<Grid item xs={12} md={3.5}>
								<Typography variant="caption" color={"text.secondary"}>
									Tombamento:
								</Typography>
								<Typography variant="subtitle2">{rows?.isTipping == "1" ? "Sim" : "Não"}</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography variant="caption" color={"text.secondary"}>
									Parceiro
								</Typography>
								<Typography variant="subtitle2">{rows?.client?.name}</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Plano
								</Typography>
								<Typography variant="subtitle2">
									{rows?.plan?.description} {rows?.baseCalc! == "encargos" ? " com encargos" : ""}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Vigência
								</Typography>
								<Typography variant="subtitle2">
									{moment(rows?.termBegin).format("L")} a {moment(rows?.expiresAt).format("L")}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Meses de Vigência
								</Typography>
								<Typography variant="subtitle2">{rows?.rentMonthsCount}</Typography>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor indenização
								</Typography>
								<Typography variant="subtitle2">{maskReal(`${rows?.lmiAmount}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Mensalidade
								</Typography>
								<Typography
									variant="subtitle2"
									sx={{ borderRadius: 2, background: "#ede2ff", color: "#7924c7" }}
									paddingX={"12px"}
									paddingY={"2px"}
									width={"40%"}
									mb={1}
								>
									{`${Number(rows?.assuranceMonthAmount ? rows?.assuranceMonthAmount : "0").toLocaleString("pt-br", {
										style: "currency",
										currency: "BRL",
									})}`}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container item direction="row" spacing={1}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Typography variant="caption" color={"text.secondary"}>
											Taxa Aplicada
										</Typography>
										<Typography variant="subtitle2">
											{rows?.propertyFinally == "commercial" ? `  ${rows?.commercialFee} %` : `${rows?.residentialFee} %`}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Typography variant="caption" color={"text.secondary"}>
											LMI
										</Typography>
										<Typography variant="subtitle2">{rows?.lmi}X</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container item direction="row" spacing={1}>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Tipo de cobrança
								</Typography>
								<Typography variant="subtitle2">{globalTranslatePayment(`${rows?.paymentMethod}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Tipo de Imovel
								</Typography>
								<Typography variant="subtitle2">{globalTranslate(`${rows?.propertyType}`)}</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
								<Typography variant="caption" color={"text.secondary"}>
									Tipo de Locação{" "}
								</Typography>

								<Typography variant="subtitle2">{globalTranslate(`${rows?.propertyFinally}`)}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} md={12}>
					{rows?.renovatedId && (
						<ClickableGrid
							container
							sm={12}
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							ml={smDown ? 0 : 2}
							sx={{ marginBottom: "16px" }}
							onClick={
								!isLoading
									? () => {
											setIsLoading(true);
											navigate(`/garantias/visualizar/${rows.renovatedId}`);
									  }
									: undefined
							}
						>
							<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1}>
								<Grid container sm={12} md={12} direction="row" spacing={1}>
									<Grid item xs={11}>
										<Typography variant="h6" color={"primary"}>
											Dados da Garantia Renovada
										</Typography>
									</Grid>
								</Grid>

								<Grid container item direction="row" spacing={1}>
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
										<Typography variant="caption" color={"text.secondary"}>
											Contratada em
										</Typography>
										<Typography variant="subtitle2">
											{rows?.renovatedProposalCreatedAt ? moment(rows?.renovatedProposalCreatedAt).format("L") : ""}
										</Typography>
									</Grid>

									<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
										<Typography variant="caption" color={"text.secondary"}>
											Início da Vigência
										</Typography>
										<Typography variant="subtitle2">
											{rows?.renovatedProposalTermBegin ? moment(rows?.renovatedProposalTermBegin).format("L") : ""}
										</Typography>
									</Grid>

									<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
										<Typography variant="caption" color={"text.secondary"}>
											Fim da Vigência
										</Typography>
										<Typography variant="subtitle2">
											{rows?.renovatedProposalExpiresAt ? moment(rows?.renovatedProposalExpiresAt).format("L") : ""}
										</Typography>
									</Grid>
								</Grid>

								<Grid container item direction="row" spacing={1} justifyContent="center">
									<Grid item xs={12}>
										<Typography variant="subtitle2" style={{ color: "#d05f01", fontSize: "14px" }}>
											VISUALIZAR GARANTIA ANTERIOR
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</ClickableGrid>
					)}

					<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} ml={smDown ? 0 : 2}>
						<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1}>
							<Grid container sm={12} md={12} direction="row" spacing={1}>
								<Grid item xs={11}>
									<Typography variant="h6" color={"primary"}>
										Dados Inquilino
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Nome
									</Typography>
									<Typography variant="subtitle2">{rows?.name}</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Documento
									</Typography>
									<Typography variant="subtitle2">
										{doc.length > 11
											? `${rows?.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")}`
											: `${rows?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
									</Typography>
								</Grid>

								<Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Nascimento
									</Typography>
									<Typography variant="subtitle2">
										{rows?.birthDate != "1899-11-30" ? moment(rows?.birthDate).format("L") : ""}
										{/* {moment(rows?.birthDate).format("L")} */}
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Nome da Mãe
									</Typography>
									<Typography variant="subtitle2">{rows?.motherName}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Relação de trabalho
									</Typography>
									<Typography variant="subtitle2">{globalTranslateProfession(`${rows?.employmentRelationShip}`)}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Estado Civil
									</Typography>
									<Typography variant="subtitle2">{globalTranslateMaritalStatus(`${rows?.maritalStatus}`)}</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1} mb="1rem">
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Celular
									</Typography>
									<Typography variant="subtitle2">{maskCell(`${rows?.phone}`)}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Telefone
									</Typography>
									<Typography variant="subtitle2">{maskCell(`${rows?.mobilePhone}`)}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Email
									</Typography>
									<Typography variant="subtitle2">{rows?.email}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} mt={2} ml={smDown ? 0 : 2}>
						<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1}>
							<Grid container sm={12} md={12} direction="row" spacing={1}>
								<Grid item xs={11}>
									<Typography variant="h6" color={"primary"}>
										Dados Locador
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Documento
									</Typography>
									<Typography variant="subtitle2">
										{docLessor?.length > 11
											? `${docLessor?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")}`
											: `${docLessor?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Nome
									</Typography>
									<Typography variant="subtitle2">{rows?.lessorName}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Nascimento
									</Typography>
									<Typography variant="subtitle2">
										{rows?.lessorBirthDate != "1899-11-30" ? moment(rows?.lessorBirthDate).format("L") : ""}
									</Typography>
								</Grid>
							</Grid>
							<Grid container item direction="row" spacing={1} mb="1rem">
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Celular
									</Typography>
									<Typography variant="subtitle2">{maskCell(`${rows?.lessorPhone}`)}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Telefone
									</Typography>
									<Typography variant="subtitle2">{maskCell(`${rows?.lessorMobilePhone}`)}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Email
									</Typography>
									<Typography variant="subtitle2">{rows?.lessorEmail}</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} mt={2} ml={smDown ? 0 : 2}>
						<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1}>
							<Grid container sm={12} md={12} direction="row" spacing={1}>
								<Grid item xs={11}>
									<Typography variant="h6" color={"primary"}>
										Dados Usuário
									</Typography>
								</Grid>
							</Grid>

							<Grid container item direction="row" spacing={1}>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Email
									</Typography>
									<Typography variant="subtitle2">{user?.email}</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Nome
									</Typography>
									<Typography variant="subtitle2">{user?.name}</Typography>
								</Grid>

								<Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
									<Typography variant="caption" color={"text.secondary"}>
										Última atualização
									</Typography>
									<Typography variant="subtitle2">
										{rows?.updatedAt
											? formatDate(new Date(rows.updatedAt))
											: rows?.createdAt
											? formatDate(new Date(rows.createdAt))
											: "Não informada"}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{loggedUser?.role == "admin" && isSuperAdmin && (
						<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} mt={2} ml={smDown ? 0 : 2}>
							<Grid container sm={12} display={"flex"} flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} spacing={1}>
								<Grid container sm={12} md={12} direction="row" spacing={1}>
									<Grid item xs={11}>
										<Typography variant="h6" sx={{ ml: 1 }} color={"secondary"}>
											Logs da Garantia
										</Typography>
									</Grid>
									{rows?.reasonId && (
										<Grid item xs={11} sx={{ ml: 1 }}>
											<Typography variant="h6" color={"primary"}>
												Motivo recusa:
											</Typography>
											<Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
												{rows?.reasonId}
											</Typography>
										</Grid>
									)}
								</Grid>

								<Grid container item direction="row" spacing={1}>
									<Grid item xs={4} display={"flex"} flexDirection={"column"}>
										<Typography variant="caption">Acesso ao log</Typography>
										<Box>
											<Button
												color="info"
												disableElevation
												variant={"outlined"}
												startIcon={<RemoveRedEyeOutlinedIcon />}
												onClick={() => handleGetLogs(id)}
											>
												Visualizar Logs
											</Button>
										</Box>
									</Grid>

									<Grid item xs={4}>
										<Box>
											<Box display={"flex"}>
												<Typography variant="caption">Enviado Serasa:</Typography>
												<Typography variant="subtitle2" sx={{ fontWeight: "bold" }} ml={2}>
													{rows?.sendSerasa == "send_serasa" ? "Sim" : "Não"}
												</Typography>
											</Box>
											<Button
												color="error"
												disableElevation
												variant={"outlined"}
												startIcon={<RemoveRedEyeOutlinedIcon />}
												onClick={() => handleGetLogsSerasa(rows?.analysisId as string)}
											>
												Log Serasa
											</Button>
										</Box>
									</Grid>

									<Grid item xs={4}>
										<Box>
											<Box display={"flex"}>
												<Typography variant="caption">Alteração de Garantia:</Typography>
												<Typography variant="subtitle2" sx={{ fontWeight: "bold" }} ml={2}>
													{rows?.sendSerasa == "send_serasa" ? "Sim" : "Não"}
												</Typography>
											</Box>
											<Button
												color="primary"
												disableElevation
												variant={"outlined"}
												startIcon={<RemoveRedEyeOutlinedIcon />}
												onClick={() => handleGetLogUpdateProposal(rows?.id as string)}
											>
												Log Alteração
											</Button>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>

			{
				// Modal Visualização do log do Serasa
			}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogsSerasa}
					isOpenModal={openModalLogsSerasa}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography mb={1.3}>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Log do Serasa
								</Typography>
							</Typography>

							{isLoadingLogsSerasa ? (
								<CircularProgress />
							) : (
								<>
									<Grid container item direction="row" spacing={1} mb={1.3}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12} md={8} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Status do Sistema
												</Typography>
												<Typography variant="subtitle2">
													{rows?.serasaAnalysisStatus ? convertStatusSerasa(rows?.serasaAnalysisStatus) : "Não fornecida"}
												</Typography>
											</Grid>
											<Grid item xs={12} md={4} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Status Fornecido Pelo Serasa
												</Typography>
												<Typography variant="subtitle2">
													{dataSerasaResponse ? convertStatusSerasa(dataSerasaResponse?.status) : rows?.serasaResponse || ""}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1} mb={1.3}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12}>
												<Typography variant="caption" color={"text.secondary"}>
													Mensagem do Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasaResponse?.serasa_response?.message || "Não fornecida"}</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12} md={8} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Renda do Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasa?.income || "Não fornecida"}</Typography>
											</Grid>
											<Grid item xs={12} md={4} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Score do Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasa?.naturalPersonSerasaScore || "Não fornecida"}</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1.3}>
										<Grid item xs={12}>
											<Grid item xs={12}>
												<Typography variant="caption" color={"text.secondary"}>
													Descrição da Mensagem do Serasa
												</Typography>
												<Typography variant="subtitle2">
													{dataSerasaResponse?.serasa_response?.message_description || "Não fornecido"}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										item
										sx={{
											border: "none",
											display: "inline-flex",
										}}
									>
										<div>
											<pre>{JSON.stringify(dataSerasaResponse, null, 1)}</pre>
										</div>
									</Grid>
								</>
							)}

							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogsSerasa}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{
				// Modal Visualização dos logs
			}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogs}
					isOpenModal={openModalLogs}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Logs
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							>
								{isLoadingLogs ? (
									<CircularProgress />
								) : (
									<Grid item>
										<TableContainer
											component={Paper}
											variant="outlined"
											style={{
												marginTop: 10,
												marginRight: 1,
												marginBottom: 1,
												width: "100%",
												padding: 1,
											}}
											aria-label="customized table"
										>
											<Table aria-label="a dense table" className="" sx={{ overflow: "auto" }}>
												<TableRow>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Data Criada</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Tipo Evento</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Nome Tabela</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold", width: "18%" }}> Referência ID Garantia</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold", width: "18%" }}> Usuário Id </TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Usuário Nome </TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Payload </TableCell>
												</TableRow>
												<TableBody sx={{ textAlign: "center" }}>
													{!dataLogs?.length && (
														<TableRow key={0}>
															<TableCell colSpan={7} sx={{ fontWeight: "normal", borderBottom: "none", textAlign: "center" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"} fontSize={20}>
																	Não há registros de logs desta análise
																</Typography>
															</TableCell>
														</TableRow>
													)}
													{dataLogs?.map((log: Logs, index: number) => (
														<TableRow key={index}>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography variant="subtitle2" color={"#080808"} fontWeight={"normal"}>
																	{moment(log?.createdDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.eventType}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.tableName}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.recordId}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.userId}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.userName}
																</Typography>
															</TableCell>
															<TableCell>
																<Button
																	color="info"
																	disableElevation
																	variant={"outlined"}
																	sx={{ alignItems: "flex-start" }}
																	startIcon={<RemoveRedEyeOutlinedIcon />}
																	onClick={() => handleGetLogsPayload(log?.payload, log?._id)}
																>
																	Payload
																</Button>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								)}
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogs}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{
				// Modal Visualização do Payload Específico
			}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogsPayload}
					isOpenModal={openModalLogsPayload}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Detalhe do Payload IdLog#{idLog}
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "inline-flex",
								}}
							>
								<div>
									<pre>{JSON.stringify(payload, null, 1)}</pre>
								</div>
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogsPayload}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{/* //######################## PROPOSAL LOG ###############################################  */}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalUpdateProposalLog}
					isOpenModal={openModalUpdateProposalLogs}
					messageType={"info"}
					enableBackdropClose={true}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Logs de alteração de garantia
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							>
								{isLoadingUpdateProposalLogs ? (
									<CircularProgress />
								) : (
									<Grid item>
										<TableContainer
											component={Paper}
											variant="outlined"
											style={{
												marginTop: 10,
												marginRight: 1,
												marginBottom: 1,
												width: "100%",
												padding: 1,
											}}
											aria-label="customized table"
										>
											<Table aria-label="a dense table" className="" sx={{ overflow: "auto" }}>
												<TableRow>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Criação Garantia</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Tipo de Ação</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Data da Alteraçao</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold", width: "18%" }}> Referência ID</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold", width: "18%" }}>Usuário Id </TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}>Usuário Nome </TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}>Payload </TableCell>
												</TableRow>
												<TableBody sx={{ textAlign: "center" }}>
													{!dataUpdateProposalLogs?.length && (
														<TableRow key={0}>
															<TableCell colSpan={7} sx={{ fontWeight: "normal", borderBottom: "none", textAlign: "center" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"} fontSize={20}>
																	Não há registros de logs desta análise
																</Typography>
															</TableCell>
														</TableRow>
													)}
													{dataUpdateProposalLogs?.map((log: UpdateProposalLogs, index: number) => (
														<TableRow key={index}>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography variant="subtitle2" color={"#080808"} fontWeight={"normal"}>
																	{moment(log?.proposal_created_at).format("DD/MM/YYYY")}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{globalTranslate(log?.proposalLog_action, "update-proposal")}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{moment(log?.proposalLog_created_at).format("DD/MM/YYYY")}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.proposalLog_id}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.proposalLog_userId}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.user_name}
																</Typography>
															</TableCell>
															<TableCell>
																<Button
																	color="info"
																	disableElevation
																	variant={"outlined"}
																	sx={{ alignItems: "flex-start" }}
																	startIcon={<RemoveRedEyeOutlinedIcon />}
																	onClick={() =>
																		handleGetLogsUpdatePayload(
																			JSON.parse(log?.proposalLog_payload_send),
																			log?.proposalLog_id
																		)
																	}
																>
																	Payload x
																</Button>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								)}
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalUpdateProposalLog}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{
				// Modal Visualização do Payload Específico
			}
			{/* {loggedUser?.role == "admin" && (
        <ModalContext
          isCloseModal={handleCloseModalLogsPayload}
          isOpenModal={openModalLogsPayload}
          messageType={"info"}
          enableBackdropClose={false}
          overrideWidthValue="100"
          showButtonCancel={false}
          IMessage={[
            <Box style={{ width: '100%' }}>
              <Typography>
                <Typography sx={{
                  color: "#000000", display: "inline-flex", fontSize: "20px",
                  fontWeight: "bold"
                }}>
                  <DescriptionOutlinedIcon sx={{
                    fontSize: "35px",
                    marginRight: "15px"
                  }} />Detalhe do Payload da Garantia IdLog# {idLog}
                </Typography>
              </Typography>

              <Grid
                container
                item
                sx={{
                  border: "none",
                  display: "inline-flex",
                }}
              >
                <div><pre>{JSON.stringify(payload, null, 1)}</pre></div>
              </Grid>
              <Typography
                sx={{
                  mt: 5,
                  textAlign: "end",
                }}
              >
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  disableElevation
                  variant={"contained"}
                  startIcon={<CloseIcon />}
                  onClick={handleCloseModalLogsPayload}
                >
                  {"Sair"}
                </Button>
              </Typography>
            </Box>
          ]}
        />
      )} */}

			{loggedUser?.role == "admin" && showModalLogUpdateProposal && (
				<ModalContext
					isCloseModal={handleCloseModalLogsUpdatePayload}
					isOpenModal={openModalLogsUpdatePayload}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Detalhe do Payload Update IdLog# {idLog}
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "inline-flex",
								}}
							>
								<div>
									<pre>{JSON.stringify(differencesProposal, null, 1)}</pre>
								</div>

								<div>
									<pre>{JSON.stringify(payload, null, 1)}</pre>
								</div>
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogsUpdatePayload}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
