import React, { useEffect, useState } from "react";
import {
	Box,
	Chip,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Pagination,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { globalbackgroundProposal, globalColorTextProposal, globalMaskDocument, globalTranslate, globalTranslateStatus } from "../../../../shared/utils";
import { HtmlTooltip } from "../../../../infra/components/tooltipsCustom/tooltipsCuston";
import { StyledBadge } from "../../../../infra/components/StyleBadge/StyledBadge";
import { ToolbarGridProposal } from "../../../../shared/components";
import { Environment } from "../../../../shared/environment";
import { renderRenovationStatus } from "../../helps/help";
import { differenceInDays, format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface TableProposalProps {
	rows: any[];
	isLoading: boolean;
	totalCount: number;
	pagina: number;
	busca: string;
	typeSearch: string;
	statusProposal: string;
	origemProposal: string;
	dataIniProposal: string;
	dataFimProposal: string;
	clientFilter: string;
	managerId: string;
	tombamento: string;
	setSearchParams: (params: any, options: any) => void;
	limit: string;
}

const TableProposal: React.FC<TableProposalProps> = ({
	rows,
	isLoading,
	totalCount,
	pagina,
	busca,
	typeSearch,
	statusProposal,
	origemProposal,
	dataIniProposal,
	dataFimProposal,
	clientFilter,
	managerId,
	tombamento,
	setSearchParams,
	limit,
}) => {
	const URL = window.location.protocol + "//" + window.location.host;
	const [filterDescription, setFilterDescription] = useState("");

	const handleFilterDescription = (description: string) => {
		const diffInDays = differenceInDays(new Date(dataFimProposal), new Date(dataIniProposal));
		const newEndDate = format(new Date(new Date().setDate(new Date().getDate() + diffInDays)), "dd/MM/yyyy");

		setFilterDescription(
			statusProposal != "expires"
				? `Filtro:  Garantias ${description} no periodo de ${format(dataIniProposal + " 03:00:00", "dd/MM/yyyy")} a ${format(
						dataFimProposal + " 03:00:00",
						"dd/MM/yyyy"
				  )}`
				: diffInDays != 0
				? `Filtro:  Garantias ${description} no periodo de ${format(new Date(), "dd/MM/yyyy")} a ${newEndDate} - próximos ${diffInDays} dias`
				: `Filtro:  Garantias ${description} no periodo de ${format(new Date(), "dd/MM/yyyy")} a ${newEndDate} - hoje`
		);
	};

	useEffect(() => {
		switch (statusProposal) {
			case "Todos":
				handleFilterDescription("criadas");
				break;
			case "active":
				handleFilterDescription("iniciadas");
				break;
			case "emited":
				handleFilterDescription("contratadas");
				break;
			case "expires":
				handleFilterDescription("a expirar");
				break;
			case "expired":
				handleFilterDescription("expiradas");
				break;
			case "canceled":
				handleFilterDescription("canceladas");
				break;
			case "suspended":
				handleFilterDescription("suspensas");
				break;
			case "renovated":
				handleFilterDescription("renovadas");
				break;
			case "renovation":
				handleFilterDescription("em renovação");
				break;
			default:
				handleFilterDescription("criadas");
				break;
		}
	}, [statusProposal, dataIniProposal, dataFimProposal]);

	function handleStatusChange(status: string): void {
		setSearchParams(
			{
				busca,
				pagina: "1",
				tipo: typeSearch,
				status,
				origem: origemProposal,
				dataini: dataIniProposal,
				datafim: dataFimProposal,
				clientFilter: clientFilter,
				managerId: managerId,
				tombamento: tombamento,
				limit: limit,
			},
			{ replace: true }
		);
	}

	return (
		<TableContainer component={Paper} variant="outlined" sx={{ marginTop: 1, width: "auto", padding: 3, ml: 1 }}>
			<Table aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell colSpan={12} sx={{ padding: "0px" }}>
							<ToolbarGridProposal
								onClickAll={() => handleStatusChange("Todos")}
								onClickActive={() => handleStatusChange("active")}
								onClickEmitidas={() => handleStatusChange("emited")}
								onClickExpires={() => handleStatusChange("expires")}
								onClickExpired={() => handleStatusChange("expired")}
								onClickCanceled={() => handleStatusChange("canceled")}
								onClickSuspended={() => handleStatusChange("suspended")}
								onClickRenovated={() => handleStatusChange("renovated")}
								onClickRenovation={() => handleStatusChange("renovation")}
								selectAll={statusProposal === "Todos"}
								selectAtive={statusProposal === "active"}
								selectEmitidas={statusProposal === "emited"}
								selectRenovated={statusProposal === "renovated"}
								selectExpires={statusProposal === "expires"}
								selectExpired={statusProposal === "expired"}
								selectSuspended={statusProposal === "suspended"}
								selectCanceled={statusProposal === "canceled"}
								selectRenovation={statusProposal === "renovation"}
								showFilterDescription={true}
								filterdescription={filterDescription}
							/>
						</TableCell>
					</TableRow>

					<TableRow sx={{ background: "#f5fbfd" }}>
						<TableCell> Cadastro</TableCell>
						<TableCell> Inquilino</TableCell>
						<TableCell> Imobiliaria</TableCell>
						<TableCell> Endereço</TableCell>
						<TableCell> Parcela </TableCell>
						<TableCell> Status </TableCell>
						<TableCell> Vigência </TableCell>
						<TableCell> Origem</TableCell>
						<TableCell># </TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{!isLoading &&
						rows.map((row) => (
							<TableRow key={row.id} hover>
								<TableCell>
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} textAlign={"center"}>
										{row.createdAt ? `${format(new Date(row.createdAt), "dd/MM/yyyy", { locale: ptBR })}` : "N/A"}
									</Typography>
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"} textAlign={"center"}>
										{row.createdAt ? `${format(new Date(row.createdAt), "HH:mm:ss", { locale: ptBR })}` : "N/A"}
									</Typography>
								</TableCell>
								<TableCell sx={{ fontWeight: "normal" }}>
									{row.name}
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
										{globalMaskDocument(row.document)}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
										{row.client?.name}
										{row.client?.status == "inactive" && " (Inativo)"}
									</Typography>
								</TableCell>

								<TableCell sx={{ color: "#959595", fontWeight: "normal" }}>
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
										{row.addressStreet != null && row.addressStreet != ""
											? `${
													row.addressComplement != null && row.addressComplement != ""
														? `${row.addressStreet} , ${row.addressNumber} - ${row.addressComplement} - ${row.addressDistrict}`
														: `${row.addressStreet} , ${row.addressNumber} - ${row.addressDistrict}`
											  }`
											: "Endereço não cadastrado"}
									</Typography>
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
										{row.addressCity != null && row.addressCity != "" ? `${row.addressCity} - ${row.addressState}` : ""}
									</Typography>
									<Box display={"flex"} justifyContent={"start"} gap={5}>
										<Typography variant="caption" fontWeight={"bold"} color={"GrayText"}>
											{row.propertyFinally != undefined ? globalTranslate(row.propertyFinally) : ""}
										</Typography>
										<Typography variant="caption" fontWeight={"bold"} color={"GrayText"}>
											Certificado : {row.certificateNumber}
										</Typography>
									</Box>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
										({row.rentMonthsCount}X)
									</Typography>
									<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal", textAlign: "center" }}>
										{parseFloat(`${row?.assuranceMonthAmount}`).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
									</Typography>
								</TableCell>
								<TableCell sx={{ textAlign: "start" }}>
									<HtmlTooltip
										disableHoverListener={row.status == "active" && row.renovatedId ? false : true}
										title={
											<React.Fragment>
												<Typography color="inherit" variant="body1" fontWeight={"500"}>
													Garantia Renovada
												</Typography>
												<Typography color="inherit" variant="caption">
													Renovada em : {row.createdAt ? `${format(new Date(row.createdAt), "dd/MM/yyyy")}` : "N/A"}
												</Typography>
											</React.Fragment>
										}
									>
										<StyledBadge
											badgeContent={"R-1"}
											color="secondary"
											invisible={row.status == "active" && row.renovatedId ? false : true}
										>
											<Chip
												size="medium"
												label={
													row.status == "active" &&
													row.renovatedId &&
													differenceInDays(
														new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:00" : "2021-01-01 03:00:01"),
														new Date().toISOString().substring(0, 10) + " 03:00:00"
													) > 0
														? ` Em Renovação `
														: ` ${globalTranslateStatus(row.status)}`
												}
												sx={{
													color: globalColorTextProposal(
														row.status == "active" &&
															row.renovatedId &&
															differenceInDays(
																new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:00" : "2021-01-01 03:00:01"),
																new Date().toISOString().substring(0, 10) + " 03:00:00"
															) > 0
															? "renovated"
															: row.status
													),
													fontWeight: "500",
													background: globalbackgroundProposal(
														row.status == "active" &&
															row.renovatedId &&
															differenceInDays(
																new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:00" : "2021-01-01 03:00:01"),
																new Date().toISOString().substring(0, 10) + " 03:00:00"
															) > 0
															? "renovated"
															: row.status
													),
													border: "0.5px solid lightgray",
												}}
											/>
										</StyledBadge>
									</HtmlTooltip>

									<Typography variant="subtitle2" color={"#fd4b4b"} fontWeight={"normal"}>
										{renderRenovationStatus(row)}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
										<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
											{row.termBegin ? `${format(new Date(row.termBegin.substring(0, 10) + " 03:00:01"), "dd/MM/yyyy")}` : "N/A"}
										</Typography>
										<Typography variant="subtitle2" color={"#f83434"} fontWeight={"normal"}>
											{row.expiresAt ? `${format(new Date(row.expiresAt.substring(0, 10) + " 03:00:01"), "dd/MM/yyyy")}` : "N/A"}
										</Typography>
									</Typography>
								</TableCell>

								<TableCell sx={{ textAlign: "start" }}>
									<IconButton
										size="small"
										sx={{
											borderRadius: "18px",
											paddingX: "15px",
											paddingY: "1px",
											marginBottom: "5px",
										}}
									>
										{row.createdFrom == "web_app" ? (
											<img src="/logogar.png" alt="web_app" width={30} />
										) : (
											<img src="/logo4seg.png" alt="web_app" width={30} />
										)}
									</IconButton>
								</TableCell>

								<TableCell sx={{ textAlign: "center" }}>
									<Tooltip title="Clique aqui para abrir a garantia" placement="top-start">
										<IconButton
											size="small"
											onClick={() => window.open(`${URL}/garantias/visualizar/${row.id}`)}
											sx={{ borderRadius: "18px" }}
										>
											<Icon
												sx={{
													color: "#0170B3",
													fontWeight: "normal",
												}}
											>
												{" "}
												crop_free_OutlinedIcon
											</Icon>
											<Typography
												variant="subtitle2"
												marginLeft={1}
												sx={{
													color: "#0170B3",
													fontWeight: "normal",
												}}
											>
												Abrir
											</Typography>
										</IconButton>
									</Tooltip>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
				{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
				<TableFooter>
					{isLoading && (
						<TableRow>
							<TableCell colSpan={3}>
								<LinearProgress variant="indeterminate" />
							</TableCell>
						</TableRow>
					)}
					{totalCount > 0 && (
						<TableRow>
							<TableCell colSpan={12} sx={{ border: "none" }}>
								<Box sx={{ display: "flex", justifyContent: "space-between" }}>
									<Box sx={{ display: "flex", gap: 2 }}>
										<FormControl sx={{ width: "auto", minWidth: "80px" }}>
											<InputLabel id="limit">Linhas </InputLabel>
											<Select
												labelId="label_Qtde"
												id="limite"
												value={limit}
												size="small"
												label="Linhas"
												onChange={(event) => {
													const value = event.target.value as string;
													setSearchParams(
														{
															busca,
															pagina: "1",
															tipo: typeSearch,
															status: statusProposal,
															origem: origemProposal,
															dataini: dataIniProposal,
															datafim: dataFimProposal,
															clientFilter: clientFilter,
															managerId: managerId,
															tombamento: tombamento,
															limit: value,
														},
														{ replace: true }
													);
												}}
											>
												<MenuItem value={"5"}> 05</MenuItem>
												<MenuItem value={"10"}>10</MenuItem>
												<MenuItem value={"25"}>25</MenuItem>
												<MenuItem value={"50"}>50</MenuItem>
											</Select>
										</FormControl>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Number(limit))}
											onChange={(_, newPage) =>
												setSearchParams(
													{
														busca,
														pagina: newPage.toString(),
														tipo: typeSearch,
														status: statusProposal,
														origem: origemProposal,
														dataini: dataIniProposal,
														datafim: dataFimProposal,
														clientFilter: clientFilter,
														managerId: managerId,
														tombamento: tombamento,
														limit: limit,
													},
													{ replace: true }
												)
											}
										/>
									</Box>

									<Box>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Number(limit) - (Number(limit) - 1)}
											{" - "}
											{Number(limit) * (pagina - 1) + Number(limit) * pagina < totalCount ? Number(limit) * pagina : totalCount} de
											<Typography
												variant="overline"
												sx={{
													fontWeight: "bold",
													color: "#0170B3",
													fontSize: "16px",
												}}
											>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</Box>
								</Box>
							</TableCell>
						</TableRow>
					)}
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

export default TableProposal;
